import React, { useState } from "react";
import Constants from "../../Constants/Constants";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import { toast } from "react-toastify";

const ProfessorComponent = ({ verifyUser }) => {
  const APP_URL = Constants.APP_URL;
  const { setProgress } = useStateContext();

  const [verifyUserData, setVerifyUserData] = useState(verifyUser);
 
  const verifyHandler = async (id) => {
    try {
      setProgress(20);
      await axiosClient
        .get("api/professor/verification/accept/" + parseInt(id))
        .then((res) => {
          if (res.data.success == true) {
            setProgress(100);
            setVerifyUserData((prev) => ({
             
                ...prev,
                is_professor_verified: res.data.data.professorVerifyData,
              
            }));

         
            toast(res.data.message, {});
          }
        })
        .catch((error) => {
          setProgress(100);
          toast(error.message, {});
        });
    } catch (error) {
      setProgress(100);
      toast(error.message, {});
    }
  };

  const rejectHandler = async (id) => {
    // console.log(id);
    try {
      setProgress(20);
      await axiosClient
        .get("api/professor/verification/reject/" + parseInt(id))
        .then((res) => {
          if (res.data.success == true) {
            setProgress(100);
            setVerifyUserData((prev) => ({
             
              ...prev,
              is_professor_verified: res.data.data.professorVerifyData,
            
          }));
            toast(res.data.message);
          }
        })
        .catch((error) => {
          setProgress(100);
          toast(error.message, {});
        });
    } catch (error) {
      setProgress(100);
      toast(error.message, {});
    }
  };

  return (
    <tr>
      <td>1</td>
      <td>{verifyUser.name}</td>

      <td>
        <a
          href={`${APP_URL}/storage/Images/users/professor/verification/PersonalImage/${verifyUser.personal_image_url}`}
          target="_blank"
        >
          <img
            style={{ width: "100px", height: "100px" }}
            src={`${APP_URL}/storage/Images/users/professor/verification/PersonalImage/${verifyUser.personal_image_url}`}
            alt=""
          />
        </a>
      </td>
      <td>
        <a
          href={`${APP_URL}/storage/Images/users/professor/verification/IdProof/${verifyUser.ID_proof_image_url}`}
          target="_blank"
        >
          <img
            style={{ width: "100px", height: "100px" }}
            className="image-size"
            src={`${APP_URL}/storage/Images/users/professor/verification/IdProof/${verifyUser.ID_proof_image_url}`}
            alt=""
          />
        </a>
      </td>
      <td>
        <a
          href={`${APP_URL}/storage/Images/users/professor/verification/TeacherProof/${verifyUser.teacher_proof}`}
          target="_blank"
        >
          <img
            style={{ width: "100px", height: "100px" }}
            src={`${APP_URL}/storage/Images/users/professor/verification/TeacherProof/${verifyUser.teacher_proof}`}
            alt=""
          />
        </a>
      </td>
      <td>{verifyUser.textial_proof}</td>
      <td>
        <button
          className="verify-btn"
          onClick={() => verifyHandler(verifyUser.user_id)}
          disabled={parseInt(verifyUserData.is_professor_verified)===3 && true}
        >
          {parseInt(verifyUserData.is_professor_verified)===3 ? "Verified" : "Verify"}
        </button>
      </td>
      <td>
        <button
          onClick={() => rejectHandler(verifyUser.user_id)}
          className="reject-btn"
          disabled={parseInt(verifyUserData.is_professor_verified)===2 && true}
        >
          {parseInt(verifyUserData.is_professor_verified)===2 ? "Rejected" : "Reject"}
        </button>
      </td>
    </tr>
  );
};

export default ProfessorComponent;
