import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { signInSchema } from "../../schemas";
//import './../register.css'
import { Link, Navigate, useNavigate } from "react-router-dom";

import "./Signin.css";
//context
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../axios-client";
import { Audio, CirclesWithBar, Hearts } from "react-loader-spinner";
import LoadingBar from "react-top-loading-bar";
import { ToastContainer, toast } from "react-toastify";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
import "font-awesome/css/font-awesome.min.css";

const Signin = () => {
  const { setToken, setUser } = useStateContext();
  const navigate = useNavigate();

  const submitBtn = useRef();
  const { progress, setProgress } = useStateContext();
  // const [isError, setIsError] = useState(true)

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [loginText, setLoginText] = useState("Log in");

  const initialValues = {
    email: "",
    password: "",
  };

  const {
    values,
    errors,
    handleBlur,
    isValid,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: signInSchema,
    onSubmit: async (values, action) => {
      try {
        setLoginText("please wait...");
        const csrf = await axiosClient
          .get("sanctum/csrf-cookie")
          .then((res) => {
            setProgress(30);
            axiosClient
              .post("api/login", values)
              .then((res) => {
                console.log(res);
                if (res.data.success == true) {
                  console.log(res.data);
                  setUser(res.data.data.user_info[0]);
                  setToken(res.data.data.token);
                  setProgress(100);
                } else {
                  toast("Login faild please try again...", {});
                  console.log(res);
                }
                return navigate("/");
              })
              .catch((err) => {
                toast("Login faild please try again...", {});
                setProgress(100);
                console.log(err);
              });
          });
      } catch (error) {
        toast(error.message, {});
        setProgress(100);
      }
      setLoginText("Log in");
      action.resetForm();
    },
  });

  return (
    <div className="container">
      <LoadingBar
        color="#D20000"
        height={3}
        loaderSpeed={1000}
        progress={progress}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="left">
        <div className="header">
          <h3>Welcome Back</h3>
        </div>
        <form
          className="register-form"
          onSubmit={handleSubmit}
          autoComplete="off"
          encType="multipart/form-data"
        >
          <div className="form-inputs-wrapper">
            <div className="form-group">
              <div className="form-label">
                <label htmlFor="name">Email</label>
                {errors.email && touched.email ? (
                  <span className="error">{errors.email}</span>
                ) : null}
              </div>
              <input
                // className={errors.email.message && "error"}
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Your Email"
                autoFocus=""
              />
            </div>
            {/* <div className="form-group-password"> */}
            <div className="form-group ">
              <div className="form-label">
                <label htmlFor="name">Password</label>
                {errors.password && touched.password ? (
                  <span className="error">{errors.password}</span>
                ) : null}
              </div>

              <div className="form-group">
              <div className="input-with-reveal">
                <input
                  // className={errors.password.message && "error"}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Password"
                />
                <span
                  className="input-group-addon change_password-reveal icon-padding-border "
                  
                  onClick={toggleShowPassword}
                >
                  <i className={ showPassword ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                </span>
                </div>
              </div>
            </div>

            <button
              className="button"
              disabled={!isValid}
              type="submit"
              aria-label="Register"
            >
              {loginText}
            </button>
          </div>
          <div className="login-link">
            <p>
              Not a member yet? <Link to="/signup">register here</Link>
            </p>
            <p>
              <Link to="/forgotpassword">Forget password</Link>
            </p>
          </div>
        </form>
      </div>
      <div className="right">
        <div className="top">
          <h4>Manage All your Exams Effeciently</h4>
          <p>
            lets get you all set up so you can verify your personal account and
            begin setting your profile
          </p>
        </div>
      </div>
    
    </div>
  );
};

export default Signin;
