import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./professorUpdateProfile.css";
import Constants from "../../Constants/Constants";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import { toast } from "react-toastify";
import { updateProfessorProfileValidation } from "../../schemas";
import { useFormik } from "formik";
const ProfessorUpdateProfile = () => {
  const { setUser, progress, setProgress } = useStateContext();
  const APP_URL = Constants.APP_URL;
  const navigate = useNavigate();
  async function getUserInfo(url = "") {
    setProgress(20);
    try {
      setProgress(30);
      const res = await axiosClient.get(url);
      setProgress(50);
      // console.log(res)
      if (res.data.success == true) {
        setProgress(80);
        setValues({
          verification_status: res.data.data.user_info.is_professor_verified,
          name: res.data.data.user_info.name,
          email: res.data.data.user_info.email,
          phone: res.data.data.user_info.phone,
          country: res.data.data.user_info.country,
          city: res.data.data.user_info.city,
          bio: res.data.data.user_info.bio,
          profileImage:
            res.data.data.user_info.profile_image_url != null
              ? res.data.data.user_info.profile_image_url
              : null,
        });
        setProgress(100);
      }
    } catch (error) {
      setProgress(100);
      toast(error.message, {});
    }
  }
  useEffect(() => {
    setProgress(10);
    getUserInfo("api/professor/profile/update");
  }, []);
  const initialValues = {
    verification_status: "",
    name: "",
    email: "",
    bio: "",
    phone: "",
    country: "",
    city: "",
    // password: "",
    // confirmPassword: "",
    profileImage: null,
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    isValid,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: updateProfessorProfileValidation,
    onSubmit: async (values, actions) => {
      console.log(values);
      setProgress(10);
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("bio", values.bio);
      formData.append("phone", values.phone);
      formData.append("country", values.country);
      formData.append("city", values.city);
      // formData.append("password", values.password);
      formData.append("profileImage", values.profileImage);
      // formData.append("confirmPassword", values.confirmPassword);
         // sending the data using axios
   try {
    const csrf = await axiosClient.get("sanctum/csrf-cookie").then((res) => {
      setProgress(20)
      axiosClient
        .post("api/professor/profile/update", formData)
        .then((res) => {
          if (res.data.success == true) {
            setProgress(100)
            setUser(res.data.data.user_info)
            toast("Updated Successfully.",{})
          }
        })
        .catch((err) => {
          if(err.response.status == 422)
          toast('Validation error',{})
          setProgress(100)
        });
    });
   } catch (error) {
    setProgress(100)
    toast(error.message,{})
   }
    },
  });


  /**
   *
   * @param {*} value
   */
  
  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      {values.verification_status != 4 && (
        <div>
          {values.verification_status == "0" && (
            <div className="alert">
              <p>
                To provide a better experience we need to verify you as a
                professor.
              </p>
              <a
                className="btn"
                onClick={(e) => {
                  return navigate("../professor/profile/verification");
                }}
              >
                Verify
              </a>
            </div>
          )}
          {values.verification_status == "1" && (
            <div className="alert">
              <p>Your Account verification is under process.</p>
              <a
                className="btn"
                onClick={(e) => {
                  return navigate("../professor/profile/verification");
                }}
              >
                View
              </a>
            </div>
          )}
          {values.verification_status == "2" && (
            <div className="alert">
              <p>
                Sorry,the provided document was not enough to verify you. please
                reupload with proper documents.
              </p>
              <a
                className="btn"
                onClick={(e) => {
                  return navigate("../professor/profile/verification");
                }}
              >
                Re-vefify
              </a>
            </div>
          )}
        </div>
      )}
      <p className="welcome">Hey, {values.name}</p>
      <div className="up-container">
        <div className="up-left">
          <div className="row">
            <div className="upload-profile">
              <label>Profile Image</label>
              <label htmlFor="profileImage" className="upload-img-wrap">
                {values.profileImage != null && (
                  <img
                    src={
                      values.profileImage == null
                        ? ""
                        : typeof values.profileImage == "string" ||
                          values.profileImage instanceof String
                        ? `${APP_URL}/storage/thumbnail_images/profiles/${values.profileImage}`
                        : URL.createObjectURL(values.profileImage)
                    }
                    alt=""
                    width={100}
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="profileImage"
                id="profileImage"
                onChange={(e) => {
                  setValues((prevState) => ({
                    ...prevState,
                    profileImage: e.target.files[0],
                  }));
                }}
              />
            </div>
            <div className="left-form-group">
              <div className="form-group">
                <div className="form-label">
                  <label htmlFor="name">Username</label>
                  {errors.name && touched.name ? (
                    <span className="error">{errors.name}</span>
                  ) : null}
                </div>
                <input
                  type="text"
                  name="name"
                  // className={errors.name.message && "error"}
                  value={values.name}
                  onChange={handleChange}
                  onblur={handleBlur}
                  placeholder="Enter Your Name"
                  dir="auto"
                />
              </div>
              <div className="form-group">
                <div className="form-label">
                  <label htmlFor="email">Email</label>
                  {errors.email && touched.email ? (
                    <span className="error">{errors.email}</span>
                  ) : null}
                </div>
                <input
                  // className={errors.email.message && "error"}
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Your Email"
                  label={"Email"}
                  dir="auto"
                />
              </div>
            </div>
          </div>
          <div className="bio">
            <div className="form-label">
              <label htmlFor="bio">Bio</label>
              {/* <span className="error">
                {errors.bio.message && errors.bio.message}
              </span> */}
            </div>
            <textarea
              name="bio"
              value={values.bio}
              onBlur={handleBlur}
              onChange={handleChange}
              id=""
              cols="30"
              rows="10"
              dir="auto"
            ></textarea>
          </div>
        </div>
        <div className="up-right">
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="phone">Phone</label>
              {/* <span className="error">
                {errors.phone.message && errors.phone.message}
              </span> */}
            </div>
            <input
              type="text"
              name="phone"
              // className={errors.phone.message && "error"}
              value={values.phone}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter Your Phone Number"
              dir="auto"
            />
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="country">Country</label>
              {/* <span className="error">
                {errors.country.message && errors.country.message}
              </span> */}
            </div>
            <input
              // className={errors.country.message && "error"}
              type="text"
              name="country"
              value={values.country}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter Your Country"
              dir="auto"

              // label={"Country"}
            />
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="city">City</label>
              {/* <span className="error">
                {errors.city.message && errors.city.message}
              </span> */}
            </div>
            <input
              // className={errors.city.message && "error"}
              type="text"
              name="city"
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter Your City"
              label={"City"}
              dir="auto"
            />
          </div>
          {/* <div className="form-group-password">
            <div className="form-group ">
              <div className="form-label">
                <label htmlFor="name">Password</label>
              </div>
              <input
                className={errors.password.message && "error"}
                type="text"
                name="password"
                value={values.password}
                onChange={(e) => handleChange(e)}
                placeholder="Enter Password"
              />
              <div className="form-error">
                <span className="error">
                  {errors.password.message && errors.password.message}
                </span>
              </div>
            </div> */}
          {/* <div className="form-group">
              <div className="form-label">
                <label htmlFor="name">Confirm Password</label>
              </div>
              <input
                className={errors.confirmPassword.message && "error"}
                type="text"
                name="confirmPassword"
                value={profileData.confirmPassword}
                onChange={(e) => handleChange(e)}
                placeholder="Confirm Your Password"
              />
              <div className="form-error">
                <span className="error">
                  {errors.confirmPassword.message &&
                    errors.confirmPassword.message}
                </span>
              </div>
            </div> */}
          {/* </div> */}
          <div className="form-group">
            <button
              className="update-professor-btn"
              type="submit"
              disabled={!isValid}
            >
              {isSubmitting ? "please wait..." : "Update"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProfessorUpdateProfile;
