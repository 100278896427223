import { object } from "prop-types"
import * as Yup from "yup"

export const signUpSchema= Yup.object({
    name:Yup.string().min(2).max(25).required("Please enter your name"),
    email:Yup.string().email().required("Please enter your email"),
    userType:Yup.string().required('Please Specify'),
    password:Yup.string().min(8).required('Please enter your password'),
    confirmPassword:Yup.string()
    .required()
    .oneOf([Yup.ref('password'),null],"Password must match"),
    term: Yup.boolean().required("Please confirm!")
})

export const signInSchema = Yup.object({
    email:Yup.string().email().required("Please enter your email"),
    password:Yup.string().min(8).required('Please enter your password'),
})


export const createQuestionBankValidation=Yup.object({

    name:Yup.string().min(5).required("Please Enter Question Bank Name"),
    // category_id:Yup.string().required("Please select category "),
})

export const createQuestionValidation = Yup.object({
    questionText:Yup.string().min(5).required("Please enter your question text"),
    correctAnswer:Yup.object().required("please choose the correct answer"),
    option1:Yup.string().min(1).trim().required("please enter option number 1"),
    option2:Yup.string().min(1).trim().required("please enter option number 2"),
    option3:Yup.string().min(1).trim().required("please enter option number 3"),
    option4:Yup.string().min(1).trim().required("please enter option number 4"),

})

export const updateQuestionValidation = Yup.object({
    questionText:Yup.string().min(5).required("Please enter your question text"),
    correctAnswer:Yup.string().required("please choose the correct answer"),
    option1:Yup.string().min(1).trim().required("please enter option number 1"),
    option2:Yup.string().min(1).trim().required("please enter option number 2"),
    option3:Yup.string().min(1).trim().required("please enter option number 3"),
    option4:Yup.string().min(1).trim().required("please enter option number 4"),

})


export const createQuizValidation = Yup.object({
    name:Yup.string().min(3).required("Please enter the quiz name"),
    timeLimit:Yup.string().required("Please select time limit"),
    score:Yup.number().required("Please enter score amount"),
    noOfQuestions:Yup.number().min(1).typeError('Please enter a valid number')
    .required('Please enter number of question')
    .positive('Please enter a positive number')
    .integer('Please enter a whole number')
     .test("is-number", "Please enter a valid number", (value) =>
      !isNaN(value)
    ),
    price:Yup.number().default(2),
    date:Yup.date().max(new Date(),'date must be before now').required('please specify the date'),
    quizDescription:Yup.string().required("Please enter the quiz description"),
    timeDuration:Yup.number().min(1).typeError('Please enter a valid number')
    .required('Please enter number of question')
    .positive('Please enter a positive number')
    .integer('Please enter a whole number')
     .test("is-number", "Please enter a valid number", (value) =>
      !isNaN(value)
    ),

    privacy:Yup.string().default("private"),

})

export const updateQuizValidation = Yup.object({
    name:Yup.string().min(3).required("Please enter the quiz name"),
    price:Yup.number().default(2),

})

export const updateProfessorProfileValidation=Yup.object({
    name:Yup.string().min(3).required("Please enter your name"),
    email:Yup.string().email().required("Please enter your email"),

})

export const changePasswordValidation= Yup.object({
    newPassword: Yup.string()
      .min(8, "Must be at least 8 characters")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  })


export const verificationValidation = Yup.object({

     personalImage: Yup
    .mixed().required("Please upload your personal image")
    .test('fileType', 'Only image files are allowed', value => value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type))
    .test('fileSize', 'Image size should be less than 5MB', value => value && value.size <= 5000000),
  IdProof: Yup
    .mixed().required("Please upload your ID proof image")
    .test('fileType', 'Only image files are allowed', value => value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type))
    .test('fileSize', 'Image size should be less than 5MB', value => value && value.size <= 5000000),
  teacherProof: Yup
    .mixed().required("Please upload your Acadamic proof image")
    .test('fileType', 'Only image files are allowed', value => value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type))
    .test('fileSize', 'Image size should be less than 5MB', value => value && value.size <= 5000000),
  textialProof: Yup.string().required('Please write a textial proof'),

})