import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "./../../axios-client";

import { useFormik } from "formik";

import "./components/FormInputs/Signup.css";
import { useStateContext } from "../../contexts/ContextProvider";
import LoadingBar from "react-top-loading-bar";
import { ToastContainer, toast } from "react-toastify";
import { signUpSchema } from "../../schemas";
import "font-awesome/css/font-awesome.min.css";
const Signup = () => {
  const submitBtn = useRef();
  const { progress, setProgress } = useStateContext();
  const navigate = useNavigate();
  const [registerText, setRegisterText] = useState("Register Me");

  const initialValues = {
    name: "",
    email: "",
    userType: "",
    password: "",
    confirmPassword: "",
    term: "",
  };
  // var data = undefined
  // const [showPasswordP, setShowPasswordP] = useState(false);
  // const [showPasswordC, setShowPasswordC] = useState(false);

  // const toggleShowPassword = () => {
  //   setShowPasswordP(!showPasswordP);
  // };

  // const toggleShowPasswordConfirm =()=>{
  //   setShowPasswordC(!showPasswordC);

  // }
  const [showPassword, setShowPassword] = useState({
    password: false,
    cpassword: false,
  });
  const toggleShowPassword = (parameter) => {
    if(parameter==="cpassword"){

      setShowPassword({ ...showPassword, cpassword: !showPassword.cpassword });
    }
    if(parameter==="password"){
      setShowPassword({...showPassword,password:!showPassword.password})
    }
  };


  const { values, errors, handleBlur, isValid , touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: signUpSchema,
      onSubmit: async (values, action) => {
        if (values.userType == 2) {
          navigate("/studentRegisteration");
        } else {
          try {
            setRegisterText("please wait...");
            setProgress(20);

            const csrf = await axiosClient
              .post("api/register", values)
              .then((res) => {
                setProgress(100);
                if (res.data.success == true)
                setRegisterText("Register Me")
                  return navigate("/otp_verification/" + values.email, {
                    replace: true,
                  });
              })
              .catch((err) => {
                setProgress(100);
                if (err.response.status == 422) {
                  setRegisterText("Register Me")
                  toast("Error, Registration failed.");
                  setProgress(100);
                } else {
                  setRegisterText("Register Me")
                  toast("Registration faild. Please try again", {});
                  setProgress(100);
                }
              });
          } catch (error) {
            setRegisterText("Register Me")
            setProgress(100);
            toast(error.message);
          }
        }
        setRegisterText("Register Me");
        action.resetForm();
      },
    });

  return (
    <div className="container">
      <LoadingBar
        color="#D20000"
        height={3}
        loaderSpeed={1000}
        progress={progress}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="left">
        <div className="header">
          <h3>Create Acount</h3>
        </div>
        <form
          className="register-form"
          onSubmit={handleSubmit}
          autoComplete="off"
          encType="multipart/form-data"
        >
          <div className="form-inputs-wrapper">
            <div className="form-group">
              <div className="form-label">
                <label htmlFor="name">Name</label>
                {errors.name && touched.name ? (
                  <span className="error">{errors.name}</span>
                ) : null}
              </div>
              <input
                type="text"
                name="name"
                // className={errors.name.message && "error"}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Your Name"
              />
            </div>
            <div className="form-group">
              <div className="form-label">
                <label htmlFor="name">Email</label>
                {errors.email && touched.email ? (
                  <span className="error">{errors.email}</span>
                ) : null}
              </div>
              <input
                // className={errors.email.message && "error"}
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Your Email"
                autoFocus=""
                label={"Email"}
              />
            </div>
            <div className="form-group-password">
              <div className="form-group ">
                <div className="form-label">
                  <label htmlFor="name">Password</label>
                </div>
                <div className="form-group input-with-reveal">
                <input
                  // className={errors.password.message && "error"}
                  type={showPassword.password ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Password"
                />
                <span
                  className="input-group-addon-sign-up icon-padding-border-cs"
                  onClick={toggleShowPassword.bind(this,"password")}
                >
                  <i className={ showPassword.password ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                </span>
                </div>
                <div className="form-error">
                  {errors.password && touched.password ? (
                    <span className="error">{errors.password}</span>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <div className="form-label">
                  <label htmlFor="name">Confirm Password</label>
                </div>
                <div className="form-group input-with-reveal">
                <input
                  // className={errors.confirmPassword.message && "error"}
                  type={showPassword.cpassword ? "text" : "password"}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Confirm Your Password"
                />
                 <span
                  className="input-group-addon-sign-up icon-padding-border-cs"
                  onClick={toggleShowPassword.bind(this,"cpassword")}
                >
                  <i className={
                  showPassword.cpassword ? "fa fa-eye-slash" : "fa fa-eye"
                }></i>
                </span>
                </div>
                <div className="form-error">
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <span className="error">{errors.confirmPassword}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-group-user-type">
              <div>
                <label htmlFor="userType">I am a</label> <br />
                <br />
                <div className="user-type-btn">
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="1"
                        name="userType"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                     <span> Teacher</span>
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="2"
                        name="userType"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                     <span> Student </span>
                    </label>
                  </div>
                  <br />
                  <div>
                    {errors.userType && touched.userType ? (
                      <span className="error">{errors.userType}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="agreement-wrap">
              <label>
                <input
                  type="checkbox"
                  name="term"
                  value={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
               <span> I agree to the term and condition </span>
              </label>
              {errors.term && touched.term ? (
                <span className="error">{errors.term}</span>
              ) : null}
            </div>
            <button className="button" disabled={!isValid} type="submit" aria-label="Register">
              {registerText}
            </button>
          </div>
          <div className="login-link">
            <p>
              Already have an account <Link to="/signin">login</Link>
            </p>
          </div>
        </form>
      </div>
      <div className="right">
        <div className="top">
          <h4>Manage All your Exams Effeciently</h4>
          <p>
            lets get you all set up so you can verify your personal account and
            begin setting your profile
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
