import axiosClient from "../../axios-client";
import React, { useEffect, useState } from "react";
import "./manageprofile.css";
import Constants from "../../Constants/Constants";
import { useStateContext } from "../../contexts/ContextProvider";
import { toast } from "react-toastify";
import { verificationValidation } from "../../schemas";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
const ProfessorAccountVerification = () => {
  const { setProgress } = useStateContext();
  const APP_URL = Constants.APP_URL;
  const navigate = useNavigate();

  const initialValues = {
    personalImage: null,
    IdProof: null,
    teacherProof: null,
    textialProof: "",
  };
  const {
    values,
    errors,
    handleBlur,
    touched,
    // isValid,
    isSubmitting,
    setSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: verificationValidation,
    onSubmit: (values, action) => {
      setProgress(10)
      let formData = new FormData();
      formData.append('personalImage',values.personalImage);
      formData.append('IdProof',values.IdProof);
      formData.append('teacherProof',values.teacherProof);
      formData.append('textialProof',values.textialProof);
      setProgress(20)
      try {
        axiosClient.post("api/professor/profile/verification",formData)
        .then((res)=>{
          setProgress(30)
          if(res.data.success==true){
            setProgress(100)
            setSubmitting(false)
            return navigate("../professor/dashboard");
          }
        }).catch((err)=>{
          if(err.response.status==422){
            toast('please upload your file correctly',{})
            setProgress(100)
            setSubmitting(false)

          }
        })
        
      } catch (error) {
        setProgress(100)
        toast(error.message,{})
        setSubmitting(false)
        
      }

    },
  });


  async function getProfessorVerificationData(url = "") {
    setProgress(10);
    try {
      const res = await axiosClient
        .get(url)
        .then((res) => {
          setProgress(20);
          // console.log(res)
          if (res.data.success == true) {
            setProgress(70);
            setValues({
              personalImage: res.data.data.verificationData.personal_image_url,
              IdProof: res.data.data.verificationData.ID_proof_image_url,
              teacherProof: res.data.data.verificationData.teacher_proof,
              textialProof: res.data.data.verificationData.textial_proof,
            });
            setProgress(100);
          }
        })
        .catch((err) => {
          if (err.response.status == 422) toast("Validation error.", {});
          setProgress(100);
        });
    } catch (error) {
      setProgress(100);
      toast(error.message, {});
    }
  }
  useEffect(() => {
    getProfessorVerificationData("api/professor/profile/getverificationdata");
  }, []);
  return (
    <div className="av-container">
      <div className="top">
        <h3>Document Verification</h3>
        <p>
          To maintain a high-quality educational environment for our students
          and to ensure that only qualified and reputable teachers are using our
          platform, we kindly request all teachers to participate in our
          verification process by providing the following documents
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-wrap">
          <div className="form-group">
            <div className="upload-profile">
              <label>Personal Image</label>
              <span className="hint">
                A clear image of yourself to verify your identity
              </span>
              <label htmlFor="personalImage" className="upload-img-wrap">
                {values.personalImage != null && (
                  <img
                    src={
                      values.personalImage == null
                        ? ""
                        : typeof values.personalImage == "string" ||
                          values.personalImage instanceof String
                        ? `${APP_URL}/storage/thumbnail_images/users/professor/verification/PersonalImage/${values.personalImage}`
                        : URL.createObjectURL(values.personalImage)
                    }
                    alt=""
                  />
                )}{" "}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="personalImage"
                id="personalImage"
                onChange={(event) => {
                  setFieldValue(
                    "personalImage",
                    event.currentTarget.files[0]
                  );
                }}
              />
              {touched.personalImage && errors.personalImage ? (
                <div className="error">{errors.personalImage}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group">
            <div className="upload-profile">
              <label>Id Proof</label>
              <span className="hint">
                {" "}
                A clear image of your government-issued identification card to
                confirm your personal information
              </span>
              <label htmlFor="IdProof" className="upload-img-wrap">
                {values.IdProof != null && (
                  <img
                    src={
                      values.IdProof == null
                        ? ""
                        : typeof values.IdProof == "string" ||
                          values.IdProof instanceof String
                        ? `${APP_URL}/storage/thumbnail_images/users/professor/verification/IdProof/${values.IdProof}`
                        : URL.createObjectURL(values.IdProof)
                    }
                    alt=""
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="IdProof"
                id="IdProof"
                onChange={(event) => {
                  setFieldValue("IdProof", event.currentTarget.files[0]);
                }}
              />
              {touched.IdProof && errors.IdProof ? (
                <div className="error">{errors.IdProof}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group">
            <div className="upload-profile">
              <label>Acadamic/professor proof</label>
              <span className="hint">
                A clear image of your academic or professional credentials to
                verify your qualifications
              </span>
              <label htmlFor="teacherProof" className="upload-img-wrap">
                {values.teacherProof != null && (
                  <img
                    src={
                      values.teacherProof == null
                        ? ""
                        : typeof values.teacherProof == "string" ||
                          values.teacherProof instanceof String
                        ? `${APP_URL}/storage/thumbnail_images/users/professor/verification/TeacherProof/${values.teacherProof}`
                        : URL.createObjectURL(values.teacherProof)
                    }
                    alt=""
                  />
                )}{" "}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="teacherProof"
                id="teacherProof"
                onChange={(event) => {
                  setFieldValue(
                    "teacherProof",
                    event.currentTarget.files[0]
                  );
                }}
              />
              {touched.teacherProof && errors.teacherProof ? (
                <div className="error">{errors.teacherProof}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="">Textial proof</label>
            <span className="hint">
              we kindly request you to provide a brief statement in the provided
              text area outlining your teaching experience. This can include
              information such as the subjects and grade levels you have taught,
              the length of time you have been teaching, and any other relevant
              teaching experience you may have
            </span>

            <textarea
              name="textialProof"
              dir="auto"
              value={values.textialProof}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>
            {touched.textialProof && errors.textialProof ? (
          <div className="error">{errors.textialProof}</div>
        ) : null}
          </div>
          <div></div>
          <div className="form-group">
            <button
              type="submit"
            >
              
              {isSubmitting ? "please wait..." : "Verify"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfessorAccountVerification;
