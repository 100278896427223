import React from "react";
import "./style.css";
import axiosClient from "../../axios-client";
import { useState } from "react";
import { toast } from "react-toastify";
import { useStateContext } from "../../contexts/ContextProvider";
import { useEffect } from "react";
import ProfessorComponent from "./ProfessorComponent";
const ProfessorVerified = () => {
  const { setProgress,user } = useStateContext();
  const [professorData, setProfessorData] = useState([]);

  async function getProfessorData(url = "") {
    setProgress(10);
    try {
      const res = await axiosClient.get(url);
      setProgress(20);
      if (res.data.success == true) {
        setProgress(100);
        setProfessorData(res.data.data.professorData);
      }
    } catch (error) {
      toast(error.message);
      setProgress(100);
    }
  }
  useEffect(() => {
    getProfessorData("api/professor/verified/data");
  }, []);

  return (
    <div className="professor-main-container">
      <div className="left-align">
        <h3>Professor Verification</h3>
        <p>
          Here, you can check whether the professor's assertions are true or
          false.
        </p>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>No</th>
            <th>Name</th>
            {/* <th>Email</th> */}
            <th>Personal Image</th>
            <th>ID Proof</th>
            <th>Teacher Proof</th>
            <th>Textial Proof</th>
            <th>Accept</th>
            <th>Refuse</th>
          </tr>
        </thead>
        <tbody>
          {professorData &&
            professorData.map((verify => 
             
              <ProfessorComponent verifyUser={verify} key={verify.user_id} />
             
            ))}

          {/* <tr>
          <td>1</td>
          <td>Row 1, Column 2</td>
          <td>Row 1, Column 2</td>
          <td>Row 1, Column 2</td>
          <td>Row 1, Column 3</td>
          <td>Row 1, Column 4</td>
          <td>Row 1, Column 5</td>
          <td>
            <button className="verify-btn">Verified</button>
          </td>
          <td>
            <button className="reject-btn">Reject</button>
          </td>
        </tr> */}
          {/* <tr>
          <td>2</td>
          <td>Row 1, Column 2</td>
          <td>Row 1, Column 2</td>
          <td>Row 2, Column 2</td>
          <td>Row 2, Column 3</td>
          <td>Row 2, Column 4</td>
          <td>Row 2, Column 5</td>
          <td>
            <button className="verify-btn">Verified</button>
          </td>
          <td>
            <button className="reject-btn">Reject</button>
          </td>
        </tr> */}
          {/* <tr>
          <td>3</td>
          <td>Row 1, Column 2</td>
          <td>Row 1, Column 2</td>
          <td>Row 3, Column 2</td>
          <td>Row 3, Column 3</td>
          <td>Row 3, Column 4</td>
          <td>Row 3, Column 5</td>
          <td>
            <button className="verify-btn">Verified</button>
          </td>
          <td>
            <button className="reject-btn">Reject</button>
          </td>
        </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default ProfessorVerified;
