import React, { useEffect, useState } from "react";

import "./mybank_questions.css";
import { useNavigate, useParams } from "react-router";
import axiosClient from "./../../axios-client";
import Header from "../../components/header/Header";
import Constants from "../../Constants/Constants";
import axiosClientClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Link } from "react-router-dom";
const QB_Questions = () => {
  const { progress, setProgress } = useStateContext();
  const APP_URL = Constants.APP_URL;
  const navigate = useNavigate();
  const params = useParams();
  const [sortBy, setSortBy] = useState();
  const [search, setSearch] = useState();
  const [totRecords, setTotRecords] = useState(0);
  const [pagination, setPagination] = useState({
    pageNo: 1,
    perPage: 20,
  });
  const [myBankQuestions, setMyBankQuestions] = useState([]);
  //get the MyBankQuestions from server
  const [loading, setLoading] = useState(true);
  //This function will filter the posts
  async function getMyBankQuestions(url = "") {
    setProgress(10);
    setLoading(true);
    try {
      setProgress(20);
      const res = await axiosClientClient
        .get(url, {
          params: {
            pageNo: pagination.pageNo,
            perPage: pagination.perPage,
            search: search,
            sortBy: sortBy,
          },
        })
        .then((res) => {
          setProgress(30);
          if (res.data.success == true) {
            setProgress(40);
            setMyBankQuestions(res.data.data.bankQuestions);
            setProgress(90);
            setTotRecords(res.data.data.totRecords);
            setProgress(100);
            if (res.data.data.totRecords <= 0)
              toast("This question bank is empty.", {});
          }
        })
        .catch((error) => {
          if (error.response.status == 422)
            toast("Failure,Validation error", {});
          setProgress(100);
        });
    } catch (error) {
      setProgress(100);
      toast(error.message, {});
    }
  }
  useEffect(() => {
    getMyBankQuestions("api/questionbank/questions/" + params.qbId);
  }, [pagination]);

  // console.log(myBankQuestions)
  //handle delete Question Bank
  const handleDelete = async (e, id) => {
    await swal({
      title: "Are you sure you want to delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setProgress(10);
        const res = await axiosClientClient
          .get(`api/questionbank/question/delete/${id}`)
          .then((res) => {
            setProgress(20);
            if (res.data.success == true) {
              toast("Deleted successfully.");
              setProgress(70);
              const newMyBankQuestions = myBankQuestions.filter(
                (qb) => qb.mqid != id
              );
              // console.log(newMyBankQuestions)
              setMyBankQuestions(newMyBankQuestions);
              setProgress(100);
            }
          })
          .catch((error) => {});
      } else {
        return;
      }
    });
  };
  // console.log(location.pathname)
  // console.log(myBankQuestions)
  //any questionbank clicked from a list it will view the details
  return (
    <div className="mybank_que-container">
      <Header
        totRecords={totRecords}
        setPagination={setPagination}
        setSortBy={setSortBy}
        setSearch={setSearch}
      />
      <div className="sub-header">
        <div className="sub-header-left">
          <p>Suggested Question</p>
        </div>
        <div className="sub-header-right">
          <Link
            to={"../professor/questionbank/suggested_questions/" + params.qbId}
            className="sug-q-btn"
          >
            <span>Suggestive Questions</span>
            <i className="fa fa-plus"></i>
          </Link>

          <Link
            to={"../professor/questionbank/create_question/" + params.qbId}
            className="create-q-btn"
          >
            <span>Create Question</span>
            <i className="fa fa-plus"></i>
          </Link>
        </div>
      </div>
      <div className="questions-container">
        {myBankQuestions &&
          myBankQuestions.map((mbq, key) => {
            return (
              <div className="question-wrapper" key={key}>
                <div className="question-wrap">
                  <span className="question-number">{++key}</span>
                  <p className="question-text" dir="auto">
                    {mbq.question_text}
                  </p>
                  <div className="question-img">
                    {mbq.question_image_url && (
                      <img
                        src={
                          `${APP_URL}/storage/thumbnail_images/questions/question/` +
                          mbq.question_image_url
                        }
                        alt=""
                      />
                    )}
                  </div>
                  <div className="action-wrap clickable" id="toggleElipse">
                    <input
                      type="checkbox"
                      className="clickable"
                      id="toggleElipse"
                    />
                    <div className="manage-qb clickable">
                      <Link
                      className="clickable"
                      id="updateQB"
                      to={"../professor/questionbank/question/update/" +
                      mbq.id}
                      
                      >
                        edit
                      
                      
                      </Link>

                      {/* <a
                        href="javascript:void()"
                        onClick={(e) => {
                          navigate(
                            "../professor/questionbank/question/update/" +
                              mbq.id
                          );
                        }}
                      >
                        
                      </a> */}

                      
                      <a
                        // href="javascript:void()"
                        href="#"
                        className="clickable"
                        onClick={(e) => handleDelete(e, mbq.mqid)}
                      >
                        delete
                      </a>
                    </div>
                  </div>
                </div>
                <div className="answers-wrap">
                  <div className="answer">
                    <span
                      className={`check-radio ${
                        mbq.correct_answer == 1 && "checked"
                      }`}
                    ></span>
                    <div className="answer-text">
                      <p dir="auto">{mbq.answer1_text}</p>
                    </div>
                    <div className="answer_img">
                      {mbq.answer1_image_url && (
                        <img
                          src={
                            `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                            mbq.answer1_image_url
                          }
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <div className="answer">
                    <span
                      className={`check-radio ${
                        mbq.correct_answer == 2 && "checked"
                      }`}
                    ></span>
                    <div className="answer-text">
                      <p dir="auto">{mbq.answer2_text}</p>
                    </div>
                    <div className="answer_img">
                      {mbq.answer2_image_url && (
                        <img
                          src={
                            `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                            mbq.answer2_image_url
                          }
                          alt=""
                        />
                      )}{" "}
                    </div>
                  </div>
                  <div className="answer">
                    <span
                      className={`check-radio ${
                        mbq.correct_answer == 3 && "checked"
                      }`}
                    ></span>
                    <div className="answer-text">
                      <p dir="auto">{mbq.answer3_text}</p>
                    </div>
                    <div className="answer_img">
                      {mbq.answer3_image_url && (
                        <img
                          src={
                            `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                            mbq.answer3_image_url
                          }
                          alt=""
                        />
                      )}{" "}
                    </div>
                  </div>
                  <div className="answer">
                    <span
                      className={`check-radio ${
                        mbq.correct_answer == 4 && "checked"
                      }`}
                    ></span>
                    <div className="answer-text">
                      <p dir="auto">{mbq.answer4_text}</p>
                    </div>
                    <div className="answer_img">
                      {mbq.answer4_image_url && (
                        <img
                          src={
                            `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                            mbq.answer4_image_url
                          }
                          alt=""
                        />
                      )}{" "}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default QB_Questions;
