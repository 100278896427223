import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./../otp/otp.css";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import LoadingBar from "react-top-loading-bar";
const ForgotPasswordOTP = () => {
  const {progress,setProgress} = useStateContext();
  const navigate = useNavigate();
  const [otp, setForgotPasswordOTP] = useState(new Array(4).fill(""));
  const [error, setError] = useState("");
  const params = useParams();
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setForgotPasswordOTP([...otp.map((d, i) => (i === index ? element.value : d))]);
    //focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleSubmit = async (e) => {
    setProgress(10)
    e.preventDefault();
    // sending the data using axios
    const csrf = await axiosClient.get("sanctum/csrf-cookie").then((res) => {
      setProgress(20)
      axiosClient
        .post("api/forgotpassword/verifyOtp", { otp: otp.join(""), email: params.email })
        .then((res) => {
          setProgress(40)
          if (res.data.success == true) {
            setProgress(100)
            return navigate("/recover_new_password");
          } else {
            setError(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  const requestOtp =async (e) => {
    e.preventDefault();
    // sending the data using axios
    const csrf = await axiosClient.get("sanctum/csrf-cookie").then((res) => {
      axios
        .get("api/resendOtp")
        .then((res) => {
          if (res.data.success == true) {
          } else {
            setError(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  return (
    <div className="otp-container">
       <LoadingBar 
        color='#D20000'
        height={3}
        loaderSpeed={1000}
        progress={progress}
      />
      <div className="otp-form-wrapper">
        <div className="otp-top-heading">
          <h4>
            <b>Varification</b>
          </h4>
          <h5>
            <b>Varification Code</b>
          </h5>
          <p class="form-massage-paragraf">
            Please enter verification code sent to email address
            {params.email}.
          </p>
          <p>
            <span className="error">{error}Please enter correct OTP</span>
          </p>
        </div>
        <form action="">
          <div className="otp-code-input-wrapper">
            {otp.map((data, index) => {
              return (
                <input
                  type="text"
                  maxLength={1}
                  placeholder={index + 1}
                  key={index}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => {
                    e.target.select();
                    e.target.addClass(".focus");
                  }}
                />
              );
            })}
          </div>
        </form>
        <button onClick={e => handleSubmit(e)}>Varify</button>
        <a href="#" onClick={e => requestOtp(e)} className="">
          Resend
        </a>
      </div>
    </div>
  );
};

export default ForgotPasswordOTP;
