import React, { useEffect, useState } from "react";
import "./quiz.css";
import Img from "./../../asset/login.jpg";
import axiosClient from "./../../axios-client";
import { useNavigate, useParams } from "react-router";
import Header from "../../components/header/Header";
import QuizList from "./components/QuizList";
import { useStateContext } from "../../contexts/ContextProvider";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import { Link } from "react-router-dom";
const Quiz = () => {
  const { setProgress } = useStateContext();
  const navigate = useNavigate();
  const params = useParams();
  const [sortBy, setSortBy] = useState('desc');
  const [search, setSearch] = useState();
  const [totRecords, setTotRecords] = useState(1);
  const [pagination, setPagination] = useState({
    pageNo: 1,
    perPage: 20,
  });
  const [quizes, setQuizes] = useState([]);


  //get the questionbanks from server
  const [loading, setLoading] = useState(true);
  //This function will filter the posts
  async function getQuizes(url = "") {
    let totRec = 0;
    setProgress(20);
    try {
      const res = await axiosClient.get(url, {
        params: {
          pageNo: pagination.pageNo,
          perPage: pagination.perPage,
          search: search,
          sortBy: sortBy,
        },
      });
      setProgress(70);
      if (res.data.success == true) {
        setProgress(100);
        setQuizes(res.data.data.quizes);
        setTotRecords(res.data.data.totRecords);
      }
    } catch (error) {
      setProgress(100);
      console.log(error);
    }
  }
  useEffect(() => {
    setProgress(10);
    getQuizes("api/quiz/manage");
  }, [pagination]);
  //handle delete Question Bank
  const handleDelete = async (e, id) => {
    await swal({
      title: "Are you sure you want to delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setProgress(10);
        try {
          const res = await axiosClient.get(`api/quiz/delete/${id}`);
          if (res.data.success == true) {
            toast("Deleted successfully.", {});
            setProgress(95);
            const newQuiz = quizes.filter((qb) => qb.quizId != id);
            setQuizes(newQuiz);
            setProgress(100);
          }
        } catch (error) {
          setProgress(100);
          toast(error.message, {});
        }
      } else {
        return;
      }
    });
    //==========================
  };
  //any questionbank clicked from a list it will view the details
  const qbClick = (e, id) => {
    if (!e.target.classList.contains("clickable"))
      return navigate("../professor/quiz/open/" + id);
  };

  return (
    <div className="quiz-container">
      <Header
        totRecords={totRecords}
        setPagination={setPagination}
        setSortBy={setSortBy}
        setSearch={setSearch}
      />
      <div className="sub-header">
        <h3>Quizes</h3>
        <Link to={"../professor/quiz/create"}  className="btn btn-group-1 btn-primary">
        <span>Create Quiz</span>
          <i className="fa fa-plus"></i>
        
        </Link>
       
      </div>
      <div className="quiz-wrapper">
        {quizes &&
          quizes.map((quiz) => {
            return (
              <QuizList
                handleDelete={handleDelete}
                qbClick={qbClick}
                quiz={quiz}
                key={quiz.quizId}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Quiz;
