import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "./suggested_questions.css";
import { useNavigate } from "react-router";
import axiosClient from "./../../axios-client";
import Header from "../../components/header/Header";
import Constants from "../../Constants/Constants";
import axiosClientClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import { ToastContainer, toast } from "react-toastify";
const SuggestiveQuestion = () => {
  const { setProgress } = useStateContext();
  const APP_URL = Constants.APP_URL;
  const navigate = useNavigate();
  const params = useParams();
  const [sortBy, setSortBy] = useState();
  const [search, setSearch] = useState();
  const [totRecords, setTotRecords] = useState();
  const [pagination, setPagination] = useState({
    pageNo: 1,
    perPage: 20,
  });
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  //get the SuggestedQuestions from server
  //This function will filter the posts
  async function getSuggestedQuestions(url = "") {
    setProgress(10);
    try {
      const res = await axiosClientClient.get(url, {
        params: {
          pageNo: pagination.pageNo,
          perPage: pagination.perPage,
          search: search,
          sortBy: sortBy,
        },
      });
      setProgress(30);
      if (res.data.success == true) {
        setProgress(70);
        setSuggestedQuestions(res.data.data.suggestedQuestions);
        setTotRecords(res.data.data.totRecords);
        setProgress(100);
      }
    } catch (error) {
      // setLoading(false)
      console.log(error);
    }
  }
  useEffect(() => {
    getSuggestedQuestions("api/questionbank/suggestionQuestion/" + params.qbId);
  }, [pagination]);


  //this function will add all selected questions at once

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(suggestedQuestions.map((li) => Number(li.id)));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  //this function will select or deselect a single question checkbox
  const handleClick = (e) => {
    const { value, checked } = e.target;
    setIsCheck([...isCheck, Number(value)]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== Number(value)));
    }
  };
  //this function will delete the question when user clicks delete btn
  const handleAdd = async (e, id, isCheckClicked = false) => {
    if (isCheck.length > 0 || isCheckClicked) {
      setProgress(10);
      try {
        const res = await axiosClientClient
          .get(`api/questionbank/question/systemsugestive/add`, {
            params: { qbId: params.qbId, questionId: JSON.stringify(id) },
          })
          .then((res) => {
            if (res.data.success == true) {
              setProgress(80);
              toast(isCheck.length + " Questions added successfully.");
              const newSuggestedQuestions = suggestedQuestions.filter((qb) => {
                return !id.includes(Number(qb.id));
              });
              setSuggestedQuestions(newSuggestedQuestions);
              setProgress(100);
            }
          })
          .catch((error) => {
            toast("Login faild please try again...", {});
            setProgress(100);
          });
      } catch (error) {
        toast(error.message, {});
        setProgress(100);
      }
    } else {
      toast("No questions are selected.", {});
      toast("Please select minimum one question.", {});
    }
  };
  return (
    <div className="suggested_que-container">
      <Header
        totRecords={totRecords}
        setPagination={setPagination}
        setSortBy={setSortBy}
        setSearch={setSearch}
      />
      <div className="sub-header">
        <div className="sub-header-left">
          <p>Suggested Question</p>
          <div class="selectall_n_checkall">
            <div class="check-all">
              <span>
                <input
                  type="checkbox"
                  className="question-check"
                  name="selectAll"
                  id="selectAll"
                  onChange={(e) => handleSelectAll(e)}
                  checked={isCheckAll}
                />
              </span>
              <span>Select all</span>
            </div>
            <div class="select-all" onClick={(e) => handleAdd(e, isCheck)}>
              <span>
                <i className="fa fa-plus"></i>
              </span>
              <span>Add Selected Questions</span>
            </div>
          </div>
        </div>
        <div className="sub-header-right"></div>
      </div>
      <div className="questions-container">
        {suggestedQuestions &&
          suggestedQuestions.map((sq, key) => (
            <div className="question-wrapper" key={key}>
              <div className="question-wrap">
                <span>
                  <input
                    type="checkbox"
                    key={sq.id}
                    name={sq.id}
                    id={sq.id}
                    value={sq.id}
                    onChange={handleClick}
                    checked={isCheck.includes(Number(sq.id))}
                  />
                </span>
                <p className="question-text" dir="auto">{sq.question_text}</p>
                <div className="question-img">
                  {sq.question_image_url && (
                    <img
                      src={
                        `${APP_URL}/storage/thumbnail_images/questions/question/` +
                        sq.question_image_url
                      }
                      alt=""
                    />
                  )}
                </div>
                <div className="sq-action">
                  <span
                    onClick={(e) => {
                      navigate(
                        "../professor/questionbank/question/sugestive/copy/" +
                          params.qbId +
                          "/" +
                          sq.id
                      );
                    }}
                  >
                    copy
                  </span>
                  <span onClick={(e) => handleAdd(e, [Number(sq.id)], true)}>
                    <i className="fa fa-plus"></i>
                  </span>
                </div>
              </div>
              <div className="answers-wrap">
                <div className="answer">
                  <span className="check-radio "></span>
                  <div className="answer-text">
                    <p dir="auto">{sq.answer1_text}</p>
                  </div>
                  <div className="answer_img">
                    {sq.answer1_image_url && (
                      <img
                        src={
                          `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                          sq.answer1_image_url
                        }
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="answer">
                  <span className="check-radio "></span>
                  <div className="answer-text">
                    <p dir="auto">{sq.answer2_text}</p>
                  </div>
                  <div className="answer_img">
                    {sq.answer2_image_url && (
                      <img
                        src={
                          `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                          sq.answer2_image_url
                        }
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="answer">
                  <span className="check-radio "></span>
                  <div className="answer-text">
                    <p dir="auto">{sq.answer3_text}</p>
                  </div>
                  <div className="answer_img">
                    {sq.answer3_image_url && (
                      <img
                        src={
                          `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                          sq.answer3_image_url
                        }
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="answer">
                  <span className="check-radio checked"></span>
                  <div className="answer-text">
                    <p dir="auto">{sq.answer4_text}</p>
                  </div>
                  <div className="answer_img">
                    {sq.answer4_image_url && (
                      <img
                        src={
                          `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                          sq.answer4_image_url
                        }
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SuggestiveQuestion;
