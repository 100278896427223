import React, { useEffect, useRef, useState } from "react";
import { BsFillPatchQuestionFill, BsThreeDotsVertical } from "react-icons/bs";
import Img from "./../../../asset/login.jpg";
import Constants from "../../../Constants/Constants";
const QBank = ({
  qbName,
  totQuestion,
  qbImg,
  qbClick,
  handleDelete,
  toggleNewQuestionBankPopup,
  id,
}) => {
  const APP_URL = Constants.APP_URL;
  // this need to be refactored
  const [isChecked, setIsChecked] = useState(false);
  const checkboxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
        setIsChecked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  // ====

  return (
    <div
      ref={checkboxRef}
      className="question-bank-wrap"
      id="qbw"
      onClick={(e) => {
        qbClick(e, id);
      }}
    >
      <div className="qb-left">
        {qbImg ? (
          <img
            src={`${APP_URL}/storage/thumbnail_images/questionbank/` + qbImg}
            alt="img"
          />
        ) : (
          <img
            src={`${APP_URL}/storage/thumbnail_images/questionbank/questionBank.png`}
            alt="img"
          />
        )}
        <div className="qb-info">
          <span>{qbName}</span>
          <span>
            <BsFillPatchQuestionFill />
            <span>{totQuestion} questions</span>
          </span>
        </div>
      </div>
      <div className="qb-right clickable" id="toggleElipse">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="clickable"
          id="toggleElipse"
        />
        {/* {true &&  */}

        <div className="manage-qb ">
          <a
            href="#"
            className="clickable"
            id="updateQB"
            onClick={(e) => {
              toggleNewQuestionBankPopup(e, id);
            }}
          >
            edit
          </a>

          <a
            href="#"
            className="clickable"
            onClick={(e) => handleDelete(e, id)}
          >
            delete
          </a>
        </div>
        {/* } */}
      </div>
    </div>
  );
};

export default QBank;
