import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router";
import {ImLocation} from 'react-icons/im';
import {HiOutlineMail} from 'react-icons/hi';
import {FiPhone}       from 'react-icons/fi';
import Img from "./../../asset/profile.jpeg";
import axiosClient from "../../axios-client";
import Constants from '../../Constants/Constants';
import { useStateContext } from '../../contexts/ContextProvider';
const OpenProfile = () => {
  const {progress,setProgress} = useStateContext();
  const APP_URL = Constants.APP_URL;
  const params = useParams();
  const [studentProfile, setStudentProfile] = useState({});
   //get the user profile data 
   async function  getStudentProfile(url=""){
    setProgress(20)
    try {
      setProgress(30)
      const res =  await axiosClient.get(url).then(res=>{
        setProgress(40)
        if(res.data.success == true){
          setProgress(90)
          setStudentProfile(res.data.data.studentData);
          setProgress(100)
        }


      }).catch (error=> {
        setProgress(100)
        console.log(error)
    })
      // console.log(res)      
  
  
  
  }catch(error){
    setProgress(100)
    console.log(error)
  }
}
  useEffect( ()=>{
    setProgress(10)
    getStudentProfile("api/quiz/enrollment/profile/view/"+ params.studentId);
  },[]);
  return (
      <div className="profile-container">
      <div className="profile-wrap">
        <div className="image-holder">
        {studentProfile.profile_image_url ? (
                    <img src={`${APP_URL}/storage/Images/profiles/${studentProfile.profile_image_url}`} alt="" />
                  ) : (
                    <img
                      src={`${APP_URL}/storage/Images/profiles/avatar.png`}
                      alt="img"
                    />
                  )}




          
        </div>
        <div className="info">
          <span className='hello'>Hello</span>
          <div className='personal-info'>
            <span className='iam'>I'm</span>
            <span className='name'>{studentProfile.username}</span>
            <span><ImLocation /></span>
            <span className='location'>{studentProfile.city} ,{studentProfile.country}</span>
          </div>
          <p>{studentProfile.bio}</p>
          <div className="contact-info">
            <div className="email">
            <span><HiOutlineMail /></span><span>{studentProfile.email}</span>
            </div>
            <div className="phone">
              <span><FiPhone /> </span><span>{studentProfile.phone}</span>
            </div>
          </div>
        </div>
      </div>
   
      </div>
  )
}

export default OpenProfile