import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axiosClient from "./../../axios-client";
import "./quiz.css";
import moment from "moment";
import Header from "../../components/header/Header";
import Constants from "../../Constants/Constants";
import { useStateContext } from "../../contexts/ContextProvider";
const OpenQuiz = () => {
  const { setProgress } = useStateContext();
  const APP_URL = Constants.APP_URL;
  const navigate = useNavigate();
  const params = useParams();
  const [sortBy, setSortBy] = useState();
  const [search, setSearch] = useState();
  const [totRecords, setTotRecords] = useState(1);
  const [pagination, setPagination] = useState({
    pageNo: 1,
    perPage: 20,
  });
  const [myQuizQuestions, setMyQuizQuestions] = useState([]);
  const [myQuizEnrollments, setMyQuizEnrollments] = useState([]);
  const [myQuizResults, setMyQuizResults] = useState([]);
  const [quizName,setQuizName]=useState("")


  // myQuizResults.sort((a, b) => {
  //   if (parseInt(a.total_correct_answer)  < parseInt(b.total_correct_answer) ) {
  //     return 1;
  //   } else if (parseInt(a.total_correct_answer) > parseInt(b.total_correct_answer)) {
  //     return -1;
  //   } else {
  //     return 0;
  //   }
  // });

  let count = 1;
  async function getMyQuizDetails(url = "") {
    setProgress(10);
    try {
      const res = await axiosClient.get(url, {
        params: {
          pageNo: pagination.pageNo,
          perPage: pagination.perPage,
          search: search,
          sortBy: sortBy,
        },
      });
      setProgress(95);
      if (res.data.success == true) {
      
        setQuizName(res.data.data.quizName.name)
        setMyQuizQuestions(res.data.data.quizQuestions);
        setMyQuizEnrollments(res.data.data.enrollments);
        setMyQuizResults(res.data.data.results);
        setTotRecords(res.data.data.totalRecord);
      
        setProgress(100);
      }
    } catch (error) {
      setProgress(100);
      console.log(error);
    }
  }
  useEffect(() => {
    getMyQuizDetails("api/quiz/open/" + params.quizId);
  }, [pagination]);
  //handle delete Question Quiz

  return (
    <div className="choose_q_manually-container">
      <Header
        totRecords={totRecords}
        setPagination={setPagination}
        setSortBy={setSortBy}
        setSearch={setSearch}
      />
      <div className="sub-header">
        <div className="sub-header-left">
          <p className={quizName.charCodeAt(0) >= 0x0590 && quizName.charCodeAt(0) <= 0x08FF ?'afg-lang':""} >{quizName}</p>
        </div>
      </div>
      <div className="quiz-details-container">
        <div className="questions-container">
          {myQuizQuestions &&
            myQuizQuestions.map((quizQuestion) => {
              return (
                <div className="question-wrapper">
                  <div className="question-wrap">
                    <span className="question-number">{count++}</span>
                    <p className="question-text" dir="auto">
                      {quizQuestion.question_text}
                    </p>
                    <div className="question-img">
                      {quizQuestion.question_image_url && (
                        <img
                          src={
                            `${APP_URL}/storage/thumbnail_images/questions/question/` +
                            quizQuestion.question_image_url
                          }
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <div className="answers-wrap">
                    <div className="answer">
                      <span
                        className={`check-radio ${
                          quizQuestion.correct_answer == 1 && "checked"
                        }`}
                      ></span>
                      <div className="answer-text">
                        <p dir="auto">{quizQuestion.answer1_text}</p>
                      </div>
                      <div className="answer_img">
                        {quizQuestion.answer1_image_url && (
                          <img
                            src={
                              `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                              quizQuestion.answer1_image_url
                            }
                            alt=""
                          />
                        )}{" "}
                      </div>
                    </div>
                    <div className="answer">
                      <span
                        className={`check-radio ${
                          quizQuestion.correct_answer == 2 && "checked"
                        }`}
                      ></span>
                      <div className="answer-text">
                        <p dir="auto"> {quizQuestion.answer2_text}</p>
                      </div>
                      <div className="answer_img">
                        {quizQuestion.answer2_image_url && (
                          <img
                            src={
                              `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                              quizQuestion.answer2_image_url
                            }
                            alt=""
                          />
                        )}{" "}
                      </div>
                    </div>
                    <div className="answer">
                      <span
                        className={`check-radio ${
                          quizQuestion.correct_answer == 3 && "checked"
                        }`}
                      ></span>
                      <div className="answer-text">
                        <p dir="auto">{quizQuestion.answer3_text}</p>
                      </div>
                      <div className="answer_img">
                        {quizQuestion.answer3_image_url && (
                          <img
                            src={
                              `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                              quizQuestion.answer3_image_url
                            }
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div className="answer">
                      <span
                        className={`check-radio ${
                          quizQuestion.correct_answer == 4 && "checked"
                        }`}
                      ></span>
                      <div className="answer-text">
                        <p dir="auto">{quizQuestion.answer4_text}</p>
                      </div>
                      <div className="answer_img">
                        {quizQuestion.answer4_image_url && (
                          <img
                            src={
                              `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                              quizQuestion.answer4_image_url
                            }
                            alt=""
                          />
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="enrollments_n_results">
          <div className="enrollments-wrapper">
            <span>Enrolled Students</span>
            <div>
              {myQuizEnrollments &&
                myQuizEnrollments.map((en) => {
                  return (
                    <div
                      className="enrollment-wrap"
                      onClick={(e) => {
                        return navigate(
                          "../professor/profile/view/" + en.student_id
                        );
                      }}
                    >
                      <div className=" enrollment-left">
                        {en.profile_image_url ? (
                          <img
                            src={
                              `${APP_URL}/storage/thumbnail_images/profiles/` +
                              en.profile_image_url
                            }
                            alt="img"
                          />
                        ) : (
                          <img
                            src={`${APP_URL}/storage/Images/profiles/avatar.png`}
                            alt="img"
                          />
                        )}
                        <div className=" enrollment-info">
                          <span>{en.username}</span>
                          <span className="date font-size-10">
                            {/* <pre>2022 July 12 12:30pm</pre> */}
                            {moment(en.created_at).format("YYYY MMMM Do h:mm a")}
                          </span>
                        </div>
                      </div>
                      <div className=" enrollment-right"></div>
                    </div>
                  );
                })}
            </div>
            <a
              onClick={(e) => {
                return navigate(
                  "../professor/quiz/enrollment/" + params.quizId
                );
              }}
            >
              View All...
            </a>
          </div>
          <div className="results-wrapper">
            <span>Students Results</span>
            <div>
              {myQuizResults &&
                myQuizResults.map((re) => {
                  return (
                    <div
                      className=" result-wrap"
                      style={{ marginBottom: "1.5em" }}
                      onClick={(e) => {
                        return navigate(
                          "../professor/profile/view/" + re.student_id
                        );
                      }}
                    >
                      <div className="result-left">
                        {re.profile_image_url ? (
                          <img
                            src={
                              `${APP_URL}/storage/Images/profiles/` +
                              re.profile_image_url
                            }
                            alt="img"
                          />
                        ) : (
                          <img
                            src={`${APP_URL}/storage/Images/profiles/avatar.png`}
                            alt="img"
                          />
                        )}
                        <div className="result-info">
                          <span>{re.username}</span>
              
                          <span>{'completd at '+ Math.floor((parseInt(re.completed_time_duration)/60))+":"+(parseInt(re.completed_time_duration)%60)+" sec"}</span>
                          <div className="ranking">
                            <div className="range">
                              <div
                                className="scored-range"
                                style={{
                                  width:
                                    (parseInt(re.total_correct_answer) /
                                      Math.round(
                                        re.total_score
                                        // )
                                      )) *
                                      100 +
                                    "%",
                                }}
                              ></div>
                            </div>
                            <div className="mark">
                              <div className="scored-mark">
                                {re.total_quiz_score} marks
                              </div>
                              <div className="heighest-mark">
                                {Math.round(re.total_score)} marks
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="result-right">
                        {re.rank}
                      </div>
                    </div>
                  );
                })}
            </div>
            <a
              onClick={(e) => {
                return navigate("../professor/quiz/result/" + params.quizId);
              }}
            >
              View All...{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenQuiz;
