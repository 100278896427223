import React, { useEffect, useState } from "react";
import "./result.css";
import { useNavigate, useParams } from "react-router";
import Img from "./../../../asset/login.jpg";
import Header from "../../../components/header/Header";
import axiosClient from "./../../../axios-client";
import Constants from "../../../Constants/Constants";
import { useStateContext } from "../../../contexts/ContextProvider";
const Result = () => {
  const { setProgress } = useStateContext();
  const APP_URL = Constants.APP_URL;
  const navigate = useNavigate();
  const params = useParams();
  const [results, setResult] = useState([]);
  //pagination and sort related states
  const [sortBy, setSortBy] = useState();
  const [search, setSearch] = useState();
  const [totRecords, setTotRecords] = useState(1);
  const [pagination, setPagination] = useState({
    pageNo: 1,
    perPage: 20,
  });
  //get results

  results.sort((a, b) => {
    if (parseInt(a.total_correct_answer)  < parseInt(b.total_correct_answer) ) {
      return 1;
    } else if (parseInt(a.total_correct_answer) > parseInt(b.total_correct_answer)) {
      return -1;
    } else {
      return 0;
    }
  });
  async function getResults(url = "") {
    setProgress(10);
    try {
      const res = await axiosClient.get(url, {
        params: {
          pageNo: pagination.pageNo,
          perPage: pagination.perPage,
          search: search,
          sortBy: sortBy,
        },
      });
      setProgress(90);
      if (res.data.success == true) {
        setResult(res.data.data.results);
        setTotRecords(res.data.data.totRecords);
        setProgress(100);
      }
    } catch (error) {
      setProgress(100);
      console.log(error);
    }
  }
  useEffect(() => {
    getResults("api/quiz/results/manage/" + params.quizId);
  }, [pagination]);
  return (
    <div className="result-container">
      <Header
        totRecords={totRecords}
        setPagination={setPagination}
        setSortBy={setSortBy}
        setSearch={setSearch}
      />
      <div className="sub-header">
        <p>History Quiz Result</p>
      </div>
      <div className="results-wrapper">
        {results &&
          results.map((re) => {
            return (
              <div
                className=" result-wrap"
                onClick={(e) => {
                  return navigate("../professor/profile/view/" + re.student_id);
                }}
              >
                <div className="result-left">
                  {re.profile_image_url ? (
                    <img
                      src={
                        `${APP_URL}/storage/thumbnail_images/profiles/` +
                        re.profile_image_url
                      }
                      alt="img"
                    />
                  ) : (
                    <img
                      src={`${APP_URL}/storage/Images/profiles/avatar.png`}
                      alt="img"
                    />
                  )}

                  <div className="result-info">
                    <span>{re.username}</span>
                    <span>{'completd at '+ Math.floor((parseInt(re.completed_time_duration)/60))+":"+(parseInt(re.completed_time_duration)%60)+" sec"}</span>
                    <div className="ranking">
                      <div className="range">
                      <div
                                className="scored-range"
                                style={{
                                  width:
                                    ( parseInt(re.total_correct_answer) /
                                      Math.round(
                                        // eval(
                                          // (parseInt(re.total_quiz_score) /
                                          //   re.total_correct_answer) *
                                          //   (re.total_correct_answer +
                                          //     re.total_wrong_answer)

                                          re.total_score
                                        // )
                                      )) *
                                      100 +
                                    "%",
                                }}
                              ></div>
                      </div>
                      <div className="mark">
                        <div className="scored-mark">
                          {re.total_quiz_score} marks
                        </div>
                        <div className="heighest-mark">
                          {Math.round(
                            // eval(
                            //   (parseInt(re.total_quiz_score) /
                            //     re.total_correct_answer) *
                            //     (re.total_correct_answer +
                            //       re.total_wrong_answer)
                            // )
                            re.total_score

                          )}{" "}
                          marks
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="result-right">{re.rank}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Result;
