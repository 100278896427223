import React, { useEffect, useState } from 'react'
import "./dashboard.css"
import QuizList from "./../quiz/components/QuizList"
// import image from "./../../asset/login.jpg"
import svgCoin from './../../asset/Coins-amico.svg'
import svgQuiz from './../../asset/Online test-pana.svg'
import svgQuiztionBank from './../../asset/Mathematics-rafiki.svg'
import svgStudent from './../../asset/Raising hand-rafiki.svg'
import { useNavigate } from 'react-router-dom'
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from 'recharts'
import { Tooltip } from '@mui/material'
import { toast } from 'react-toastify'
import axiosClient from '../../axios-client'
import { useStateContext } from '../../contexts/ContextProvider'
import Constants from '../../Constants/Constants'
const Dashboard = () => {
  const [profileData, setProfileData] = useState({
    verification_status:"",
    name: "",
    email: "",
    bio: "",
    phone: "",
    country: "",
    city: "",
    password: "",
    confirmPassword: "",
    profileImage:null
  });
  const {setProgress} = useStateContext();
  const data = [
    {
      name: 'Saturday',
      uv: 100,
      pv: 400,
      amt: 400,
    },
    {
      name: 'Sanday',
      uv: 300,
      pv: 198,
      amt: 210,
    },
    {
      name: 'Monday',
      uv: 200,
      pv: 800,
      amt: 90,
    },
    {
      name: 'Tuesday',
      uv: 280,
      pv: 908,
      amt: 200,
    },
    {
      name: 'Wednesday',
      uv: 890,
      pv: 800,
      amt: 281,
    },
    {
      name: 'Tursday',
      uv: 290,
      pv: 800,
      amt: 200,
    },
    {
      name: 'Friday',
      uv: 390,
      pv: 400,
      amt: 100,
    },
  ];
  const navigate = useNavigate()
  const [dashboardData, setDashboardData] = useState([]);
  const [topStudents,setTopStudents]= useState([])
  //get the questionbanks from server
  const [loading, setLoading] = useState(true);
  //This function will filter the posts
  // setTopStudents(topStudents)

  
  topStudents.sort((a,b)=>{
    if(parseInt(a.average_rank)< parseInt(b.average_rank)){
      return -1;
    }
    else if(parseInt(a.average_rank)> parseInt(b.average_rank)){
      return 1;
    }
    else{
      return 0;
    }
  })
  const APP_URL = Constants.APP_URL;
  async function getDashboardData(url = "") {
    setProgress(10)
    try {
      const res = await axiosClient.get(url);
      setProgress(20)
      if (res.data.success == true) {
        setProgress(100)
        setDashboardData(res.data.data);
        setTopStudents(res.data.data.top_students)
      }
    } catch (error) {
      toast(error.message)
      setProgress(100)
    }
  }
  useEffect(() => {
    getDashboardData("api/professor/dashboard");
  }, []);
  
//any questionbank clicked from a list it will view the details
const qbClick = (e, id) => {
  if (!e.target.classList.contains("clickable"))
    return navigate("../professor/quiz/open/" + id);
};
//This function let you delete a question bank
 //get the user profile data 
 async function  getUserInfo(url=""){
  setProgress(20)
  try {
    setProgress(30)
    const res =  await axiosClient.get(url);
    setProgress(50)
    // console.log(res)      
    if(res.data.success == true){
      setProgress(80)
     setProfileData({...profileData,
      ['verification_status']:res.data.data.user_info.is_professor_verified,
      ['name']:res.data.data.user_info.name,
      ['email']:res.data.data.user_info.email,
      ['phone']:res.data.data.user_info.phone,
      ['country']:res.data.data.user_info.country,
      ['city']:res.data.data.user_info.city,
      ['bio']:res.data.data.user_info.bio,
      ['profileImage']:(res.data.data.user_info.profile_image_url != null)?res.data.data.user_info.profile_image_url:null
    })
    setProgress(100)
    }
} catch (error) {
    setProgress(100)
    toast(error.message,{})
}


}
useEffect( ()=>{
  setProgress(10)
  getUserInfo("api/professor/profile/update");
},[]);

  return (
    <div className="dashboard-container">
       {profileData.verification_status != 4 && (
        <div >
        {(profileData.verification_status == '0') && ( <div className="alert">
          <p>
             To provide a better experience we need to verify you as a professor.
          </p>
          <a  className="btn" onClick={ (e)=>{return navigate("../professor/profile/verification")}} >
            Verify
          </a></div> )
          
        }
        {(profileData.verification_status == '1') && ( <div className="alert">
          <p>
             Your Account verification is under process.
          </p>
          <a  className="btn" onClick={ (e)=>{return navigate("../professor/profile/verification")}} >
            View
          </a></div> )
          
        }
        {(profileData.verification_status == '2') && ( <div className="alert">
          <p>
             Sorry,the provided document was not enough to verify you. please reupload with proper documents.
          </p>
          <a  className="btn" onClick={ (e)=>{return navigate("../professor/profile/verification")}} >
            Re-vefify
          </a></div> )
          
        }
        </div>
      )}
      <h3>Dashboard</h3>
      <div className="card-container">
        <div className="card" onClick={e=>navigate('../professor/quiz/manage')}>
          <div className="c--left">
            <span className="card-name">
              Quizzes
            </span>
            <span className="total-item">
              {dashboardData.total_quizzes}
            </span>
          </div>
          <div className="c--image">
            <img src={svgQuiz} alt="" />
          </div>
        </div><div className="card" >
          <div className="c--left">
            <span className="card-name">
              Coin
            </span>
            <span className="total-item">
            {dashboardData.total_coin}
            </span>
          </div>
          <div className="c--image">
            <img src={svgCoin} alt="" />
          </div>
        </div><div className="card" onClick={e=>navigate('../professor/questionbank/manage')}>
          <div className="c--left">
            <span className="card-name">
              Question Banks
            </span>
            <span className="total-item">
            {dashboardData.total_questionbanks}
            </span>
          </div>
          <div className="c--image">
            <img src={svgQuiztionBank} alt="" />
          </div>
        </div><div className="card" >
          <div className="c--left">
            <span className="card-name">
              Students
            </span>
            <span className="total-item">
            {dashboardData.total_students}
            </span>
          </div>
          <div className="c--image">
            <img src={svgStudent} alt="" />
          </div>
        </div>
      </div>
      <div className="statistic-container">
        <div className="total-students">
        <p>Total Students</p>
        <LineChart
          width={720}
          height={160}
          data={data}
          margin={{
           
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
        </LineChart>
        </div>
        <div className="top-students">
          <p>Top Students</p>
          {topStudents &&
           topStudents.map((student)=>(
            <div className="student-wrap" key={student.id} onClick={e=>{return navigate("../professor/profile/view/"+student.user_id)}}>
            {/* <img src={image} alt="" /> */}
            {student.profile_image_url ? (
                    <img
                      src={
                        `${APP_URL}/storage/thumbnail_images/profiles/` +
                        student.profile_image_url
                      }
                      alt="img"
                    />
                  ) : (
                    <img
                      src={`${APP_URL}/storage/Images/profiles/avatar.png`}
                      alt="img"
                    />
                  )}
            <p className="student-name">{student.username}</p>
          </div>
           ))}
        </div>
      </div>
      <div className="top-quizzes quiz-wrapper">
      {dashboardData.top_quizzes && dashboardData.top_quizzes.map((quiz,index)=>(index<4 &&
         <QuizList 
         handleDelete={''}
         qbClick = {qbClick}
         quiz = {quiz}
         key={quiz.quizId}
         />
      ))}
      </div>
    </div>
  )
}

export default Dashboard