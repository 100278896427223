import React, { useEffect, useState } from "react";
//import './../register.css'
import { Link, Navigate, useNavigate } from "react-router-dom";
// import InputField from "../../components/form/InputField";
import { Validators } from "../../utils/Validator";
import { validateInput } from "../../utils/Validator";
import "./../sign-in/Signin.css";
//context 
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../axios-client";
import { Audio, CirclesWithBar, Hearts } from "react-loader-spinner";
import LoadingBar from "react-top-loading-bar";
import { ToastContainer,toast } from 'react-toastify';
const RecoverPassword = () => {
  const {progress,setProgress} = useStateContext();
  const {setToken, setUser} = useStateContext();
  const navigate = useNavigate();
  const [registerData, setRegisterData] = useState({
    confirm_password: "",
    password: "",
  });
  const [errors, seterrors] = useState({
    confirm_password: "",
    password: "",
  });
  const [validators, setValidators] = useState({
    confirm_password:
    [ 
      {
        check: Validators.required,
        message: "field is required",
      },
    ],
    password: 
      [{
        check: Validators.required,
        message: "This field is required",
      },
    ],
  });
  /**
   *
   * @param {*} value
   */
  const handleChange = (event) => {
    setRegisterData((e) => ({ ...e, [event.target.name]: event.target.value }));
    //handle error

    seterrors((e) => ({
      ...e,
      [event.target.name]: validateInput(
        validators[event.target.name],
        event.target.value
      ),
    }));
    //console.log(value)
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(20)
    try {
      const csrf = await axiosClient.get("sanctum/csrf-cookie").then((res) => {
        setProgress(30)
        axiosClient
        .post("api/login", registerData)
        .then((res) => {
          console.log(res)
          if (res.data.success == true){
            setUser(res.data.data.user_info[0])
            setToken(res.data.data.token)
            setProgress(100)
          }else{
            toast("Login faild please try again...",{})
          }
          return navigate('/')
        })
        .catch((err) => {
          toast("Login faild please try again...",{})
          setProgress(100)
        });
      });
    } catch (error) {
      toast(error.message,{})
      setProgress(100)
    }
   
  };
  return (
    <div className="container">
       <LoadingBar 
        color='#D20000'
        height={3}
        loaderSpeed={1000}
        progress={progress}
      />
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
      <div className="left">
        <div className="header">
          <h3>Welcome Back</h3>
        </div>
        <form
          className="register-form"
          onSubmit={handleSubmit}
          autoComplete="off"
          encType="multipart/form-data"
        >
          <div className="form-inputs-wrapper">
            <div className="form-group">
              <div className="form-group ">
                <div className="form-label">
                  <label htmlFor="name">Password</label>
                </div>
                <input
                className={errors.password.message && ("error")}
                  type="text"
                  name="password"
                  value={registerData.password}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter Password"
                />
                <div className="form-error">
                  <span className="error">{errors.password.message && (errors.password.message)}</span>
                </div>
              </div>
              <div className="form-group ">
                <div className="form-label">
                  <label htmlFor="confirm_password">Confirm Password</label>
                </div>
                <input
                className={errors.confirm_password.message && ("error")}
                  type="text"
                  name="confirm_password"
                  value={registerData.confirm_password}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter Password"
                />
                <div className="form-error">
                  <span className="error">{errors.confirm_password.message && (errors.confirm_password.message)}</span>
                </div>
              </div>
            </div>
            <button
              className="button"
              type="submit"
              aria-label="Register"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
          <div className="login-link">
            <p>
              I do not have an account <Link to="/signup">Create new</Link>
            </p>
            <p>
              <Link to="/signin">Signin</Link>
            </p>
          </div>
        </form>
      </div>
      <div className="right">
        <div className="top">
          <h4>Manage All your Exams Effeciently</h4>
          <p>
            lets get you all set up so you can verify your personal account and
            begin setting your profile
          </p>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
