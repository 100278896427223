import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosClient from "./../../axios-client";

import "./createquestion.css";
import Constants from "../../Constants/Constants";
import { toast } from "react-toastify";
import { useStateContext } from "../../contexts/ContextProvider";
import { createQuestionValidation } from "../../schemas";
import { useFormik } from "formik";
import Select from "react-select";
const CreateQuestion = () => {
  const APP_URL = Constants.APP_URL;
  const { progress, setProgress } = useStateContext();
  const navigate = useNavigate();
  const params = useParams();


  /**
   *
   * @param {*} value
   */

  const initialValues = {
    questionText: "",
    question_image: "",
    correctAnswer: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option1_image: "",
    option2_image: "",
    option3_image: "",
    option4_image: "",
  };

  const {
    values,
    errors,
    handleBlur,
    setFieldValue,
    touched,
    isSubmitting,
    setSubmitting,
    isValid,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: createQuestionValidation,
    onSubmit: async (values, action) => {
      setProgress(10);
      let formData = new FormData();
      formData.append("question_text", values.questionText);
      formData.append("correct_answer", values.correctAnswer.value);
      formData.append("question_image", values.question_image);
      formData.append("answer1_text", values.option1);
      formData.append("answer2_text", values.option2);
      formData.append("answer3_text", values.option3);
      formData.append("answer4_text", values.option4);
      formData.append("answer1_image", values.option1_image);
      formData.append("answer2_image", values.option2_image);
      formData.append("answer3_image", values.option3_image);
      formData.append("answer4_image", values.option4_image);
      try {
        const csrf = await axiosClient
          .get("sanctum/csrf-cookie")
          .then((res) => {
            setProgress(20);
            axiosClient
              .post("api/questionbank/storeQuestion/" + params.qbId, formData)
              .then((res) => {
                if (res.data.success == true) {
                  setProgress(100);
                  toast("Question created.");
               
                  action.resetForm();
                  setSubmitting(false);
                }
              })
              .catch((err) => {
                setProgress(100);
                if (err.response.status == 422) {
                  toast("Error, Question creation failure.");
                  toast("Please fill in all the fileds.");
                }
              });
          });
      } catch (error) {
        setProgress(100);
        toast(error.message);
      }
      action.resetForm();
      setSubmitting(false);
    },
  });

  const answers = [
    { value: "1", label: "answer 1" },
    { value: "2", label: "answer 2" },
    { value: "3", label: "answer 3" },
    { value: "4", label: "answer 4" },
  ];

  return (
    <form action="">
      <p className="welcome">Create Question</p>
      <div className="create-question-container">
        <div className="create-question-left">
          <div className="question">
            <div className="form-label">
              <label htmlFor="">Question</label>
              {errors.questionText && touched.questionText ? (
                <span className="error">{errors.questionText}</span>
              ) : null}
            </div>
            <textarea
              dir="auto"
              name="questionText"
              value={values.questionText}
              onChange={handleChange}
              onBlur={handleBlur}
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div className="row">
            <div className="right-form-group">
             
              <div className="form-group">
                <div className="form-label">
                  <label htmlFor="name">Correct Answer</label>
                  {errors.correctAnswer && touched.correctAnswer ? (
                    <span className="error">{errors.correctAnswer}</span>
                  ) : null}
                </div>
                <Select
                  className="select_formik_input"
                  name="correctAnswer"
                  onBlur={handleBlur}
                  value={values.correctAnswer}
                  options={answers}
                  onChange={(selectedOption) => {
                    setFieldValue("correctAnswer", selectedOption);
                  }}
                  placeholder="choose answer"
                />

              </div>
            </div>
            <div className="upload-profile">
              <label>Question Image</label>
              <label htmlFor="question_image" className="upload-img-wrap">
                {values.question_image && (
                  <img
                    src={
                      values.question_image != ""
                        ? URL.createObjectURL(values.question_image)
                        : ""
                    }
                    alt=""
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="question_image"
                id="question_image"
                onChange={(e) =>
                  setFieldValue("question_image", e.target.files[0])
                }
                onBlur={handleBlur}
              />
            </div>
          </div>
        </div>
        <div className="create-question-right">
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Option1</label>
              {errors.option1 && touched.option1 ? (
                <span className="error">{errors.option1}</span>
              ) : null}
            </div>
            <div className="input-file-wrap">
              <input
                dir="auto"
                type="text"
                name="option1"
                value={values.option1}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="option 1"
              />
              <label htmlFor="option1Img" className="select-file-wrap">
                {values.option1_image && (
                  <img
                    src={
                      values.option1_image != ""
                        ? URL.createObjectURL(values.option1_image)
                        : ""
                    }
                    alt=""
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="option1_image"
                id="option1Img"
                onChange={(e) =>
                  setFieldValue("option1_image", e.target.files[0])
                }
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Option2</label>
              {errors.option2 && touched.option2 ? (
                <span className="error">{errors.option2}</span>
              ) : null}
            </div>
            <div className="input-file-wrap">
              <input
                dir="auto"
                type="text"
                name="option2"
                value={values.option2}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="option 2"
              />
              <label htmlFor="option2Img" className="select-file-wrap">
                {values.option2_image && (
                  <img
                    src={
                      values.option2_image != ""
                        ? URL.createObjectURL(values.option2_image)
                        : ""
                    }
                    alt=""
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="option2_image"
                id="option2Img"
                onChange={(e) =>
                  setFieldValue("option2_image", e.target.files[0])
                }
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Option3</label>
              {errors.option3 && touched.option3 ? (
                <span className="error">{errors.option3}</span>
              ) : null}
            </div>
            <div className="input-file-wrap">
              <input
                dir="auto"
                type="text"
                name="option3"
              
                value={values.option3}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="option 3"
              />
              <label htmlFor="option3Img" className="select-file-wrap">
                {values.option3_image && (
                  <img
                    src={
                      values.option3_image != ""
                        ? URL.createObjectURL(values.option3_image)
                        : ""
                    }
                    alt=""
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="option3_image"
                id="option3Img"
                onChange={(e) =>
                  setFieldValue("option3_image", e.target.files[0])
                }
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Option4</label>
              {errors.option4 && touched.option4 ? (
                <span className="error">{errors.option4}</span>
              ) : null}
            </div>
            <div className="input-file-wrap">
              <input
                dir="auto"
                type="text"
                name="option4"
                value={values.option4}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="option 4"
              />
              <label htmlFor="option4Img" className="select-file-wrap">
                {values.option4_image && (
                  <img
                    src={
                      values.option4_image != ""
                        ? URL.createObjectURL(values.option4_image)
                        : ""
                    }
                    alt=""
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="option4_image"
                id="option4Img"
                onChange={(e) =>
                  setFieldValue("option4_image", e.target.files[0])
                }
                onBlur={handleBlur}
              />
            </div>
          </div>

          <div className="form-group">
            <button
              type="submit"
              onClick={(e) => handleSubmit(e)}
              disabled={!isValid}
            >
              {isSubmitting ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateQuestion;
