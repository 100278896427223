import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Constants from "../../../Constants/Constants";
const QuizList = ({ quiz, handleDelete, qbClick }) => {
  const APP_URL = Constants.APP_URL;

  const navigate = useNavigate();

  const params = useParams();

  const total_selected_questions = quiz.total_selected_questions;
  const totalQuestions = total_selected_questions
    .slice(1, -1)
    .split(",")
    .map(Number);

  // let result = "";
  // if (quiz.completed && typeof quiz.totCompleted === "number") {
  //   if (quiz.totCompleted >= 4) {
  //     result = "+" + (quiz.totCompleted - 4) + " Completed";
  //   } else {
  //     result = "+" + quiz.totCompleted + " Completed";
  //   }
  // } else if (quiz.enrollments && typeof quiz.totEnrolled === "number") {
  //   if (quiz.totEnrolled >= 4) {
  //     result = "+" + (quiz.totEnrolled - 4) + " Enrolled";
  //   } else {
  //     result = "+" + quiz.totEnrolled + " Enrolled";
  //   }
  // }
  let result =""
  if (quiz.completed && typeof quiz.totCompleted === "number") {
  
      result =quiz.totCompleted + " Completed";
  } else if (quiz.enrollments && typeof quiz.totEnrolled === "number") {
 
      result =quiz.totEnrolled + " Enrolled";
    
  }

  return (
    <div
      className="quiz-wrap"
      key={quiz.quizId}
      onClick={(e) => qbClick(e, quiz.quizId)}
    >
      <div className="quiz-left">
        {quiz.image_url ? (
          <img
            src={`${APP_URL}/storage/thumbnail_images/quiz/` + quiz.image_url}
            alt="img"
          />
        ) : (
          <img
            src={`${APP_URL}/storage/thumbnail_images/quiz/quiz.jpg`}
            alt="img"
          />
        )}

        <div className="quiz-info">
          <div className="info">
            <span>{quiz.name}</span>
          </div>
          <span className="date font-size-10">
            {" "}
            {moment(quiz.quiz_date).format("YYYY MMMM Do h:mm a")}

            <span style={{ marginLeft:"1em" }} className={quiz.time_constraint==="open" ?"btn rounded padding-4 btn-primary" :"btn rounded padding-4 bg-warning" }> {quiz.time_constraint} </span> 

           
          </span>
          <div className="inrolled-img">
            {quiz.enrollments &&
              quiz.enrollments.map((qe, index) => {
                return qe.profile_image_url ? (
                  <img
                    src={
                      `${APP_URL}/storage/thumbnail_images/profiles/` +
                      qe.profile_image_url
                    }
                    alt="img"
                  />
                ) : (
                  <img
                    src={`${APP_URL}/storage/Images/profiles/avatar.png`}
                    alt="img"
                  />
                );
              })}
            {quiz.completed &&
              quiz.completed.map((qe, index) => {
                return qe.profile_image_url ? (
                  <img
                    src={
                      `${APP_URL}/storage/thumbnail_images/profiles/` +
                      qe.profile_image_url
                    }
                    alt="img"
                  />
                ) : (
                  <img
                    src={`${APP_URL}/storage/Images/profiles/avatar.png`}
                    alt="img"
                  />
                );
              })}

              
            <span
              className={
                quiz.completed
                  ? "btn rounded padding-4 clickable btn-primary"
                  : "btn rounded padding-4 clickable bg-warning"
              }
              onClick={(e) => {
                quiz.completed
                  ? navigate("../professor/quiz/result/" + quiz.quizId)
                  : navigate("../professor/quiz/enrollment/" + quiz.quizId);
              }}
            >
           
              {result}
            </span>
          </div>
        </div>
      </div>
      <div className="quiz-right">
        <input type="checkbox" className="clickable" id="toggleElipse" />
        <div className="manage-quiz clickable">
          <a
            className="clickable"
            id="updateQB"
            onClick={(e) => {
              return navigate("../professor/quiz/edit/" + quiz.quizId);
            }}
          >
            edit
          </a>
          <a
            className="clickable"
            onClick={(e) => handleDelete(e, quiz.quizId)}
          >
            delete
          </a>
        </div>

        {parseInt(quiz.total_question) > totalQuestions.length ? (
          <a
            className="clickable btn rounded padding-4 bg-warning status-btn "
            onClick={(e) =>
              navigate(
                "../professor/quiz/chooseQuestions/manually/" + quiz.quizId
              )
            }
          >
            Pending
          </a>
        ) : quiz.privacy == "private" ? (
          <a
            className="clickable btn rounded padding-4 bg-warning status-btn "
            onClick={(e) =>
              navigate(
                "../professor/quiz/chooseQuestions/manually/" + quiz.quizId
              )
            }
          >
            lets publish
          </a>
        ) : (
          <a className="clickable btn rounded padding-2 bg-primary status-btn btn-group-1">
            {/* <i className="fa fa-light fa-circle-question"></i> */}
            Published
          </a>
        )}
      </div>
    </div>
  );
};

export default QuizList;
