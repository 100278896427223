import React, { useState, useEffect } from "react";
import "./choosequestions.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosClient from "./../../../axios-client";
import Header from "../../../components/header/Header";
import usePagination from "../../../hooks/pagination/usePagination";
import { useStateContext } from "../../../contexts/ContextProvider";
import { toast } from 'react-toastify';
// import "font-awesome/css/font-awesome.min.css"
const ChooseManually = () => {
  const { setProgress } = useStateContext();
  const params = useParams();
  // console.log(params.quizId)
  const navigate = useNavigate();
  //pagination
  const [sortBy, setSortBy] = useState();
  const [search, setSearch] = useState();
  const [totRecords, setTotRecords] = useState(0);
  const [pagination, setPagination] = useState({
    pageNo: 1,
    perPage: 20,
  });
  const [addedQuestions, setAddedQuestions] = useState([]);
  const [suggestionQuestions, setSuggestionQuestions] = useState([]);
  const [questionBanks, setQuestionBanks] = useState([]);
  const [selectedQB, setSelectedQB] = useState([]);
  const [neededQuestionSelected, setNeededQuestionSelected] = useState([]);
  const [totQuizQuestionCount, setTotQuizQuestionCount] = useState();
  const [totalAmountOfQuestion, setTotalAmountOfQuestion] = useState(0);


  // console.log(totalAmountOfQuestion)
  // console.log(totQuizQuestionCount)
  // console.log(totQuizQuestionCount)
  // console.log(addedQuestions.length)

  async function getQuestionBanks(url = "", isQBChange = true) {
    setProgress(10)
    try {
      const res = await axiosClient.get(url, {
        params: { pageNo: pagination.pageNo, perPage: pagination.perPage, search: search, sortBy: sortBy }
      }).then(res => {
        setProgress(20)
        if (res.data.success == true) {
          setTotalAmountOfQuestion(res.data.data.totAdedQuestions)
          // console.log('totalAmountof question',totalAmountOfQuestion)
          setProgress(80)
          // setTotalAmountOfQuestion((res.data.data.addedQuestion).length)
          setAddedQuestions(res.data.data.addedQuestions);
          setSuggestionQuestions(res.data.data.suggestedQuestion);
          setQuestionBanks(res.data.data.questionBanks);
          setTotRecords(res.data.data.totRecords);
          // setTotRecords(res.data.data.totAdedQuestions);
          // console.log(res.data.data.totRecords);
          (isQBChange) && setSelectedQB(res.data.data.questionBanks[0]);

          let totQuizQuestionCount = res.data.data.questionBanks.reduce(function (prev, cur) {
            return parseInt(prev) + parseInt(cur.totQty);
          }, 0);
          // console.log(totQuizQuestionCount)
          setTotQuizQuestionCount(totQuizQuestionCount);
          setProgress(100)

        }
      }).catch(error => {
        setProgress(1000)
        toast(error.message)
      });

    } catch (error) {
      setProgress(100)
      toast(error.message)
    }
  }
  useEffect(() => {
    getQuestionBanks(
      "api/quiz/addQuestions"+"/manually/" + params.quizId
    );
  }, [pagination]);

  useEffect(() => {
    getQuestionBanks("api/quiz/addQuestions"+"/manually/"  + params.quizId);
  }, []);



  //this function will delete the question when user clicks delete btn
  const handleDelete = async (e, id) => {
    try {
      const res = await axiosClient.get(
        `api/quiz/question/manually/delete`,
        { params: { quizId: params.quizId, questionId: id } }
      ).then(res => {
        if (res.data.success == true) {
          const total_questions = res.data.data.question_ids
          setTotalAmountOfQuestion(total_questions.length)

          toast('Deleted successfully.', {})

          const newQuestions = addedQuestions.filter((qb) => qb.mqid != id);
          const removedQuestion = addedQuestions.filter((qb) => qb.mqid == id);
          // console.log(res.data.data.newQuizQuestion)
          // console.log(removedQuestion)

          if (res.data.data.newQuizQuestion != null) {
            res.data.data.newQuizQuestion != null
              ? setSuggestionQuestions((nq) => ([
                ...nq,
                res.data.data.newQuizQuestion,
              ]))
              : setSuggestionQuestions(removedQuestion);
          }
          setAddedQuestions(newQuestions);
          // console.log(suggestionQuestions)
          console.log("deleted");
        }
      }).catch(error => {
        toast(error.message, {})
        setProgress(100)
      });
    } catch (error) {
      toast(error.message, {})
      setProgress(100)
    }
  };
  //this function will delete the question when user clicks delete btn
  const handleAdd = async (e, id, isCheckClicked = false) => {
    try {
      await axiosClient.get(
        `api/quiz/question/manually/add`,
        { params: { quizId: params.quizId, questionId: JSON.stringify(id) } }
      ).then(res => {
        if (res.data.success == true) {
          const totalQuestions = res.data.data
          setTotalAmountOfQuestion(totalQuestions.length)
          toast('Question selected.', {})
          const newAddQuestion = suggestionQuestions.filter((qb) => {
            return (id.includes(Number(qb.mqid)))
          });
          const newSuggestedQuestions = suggestionQuestions.filter(
            (qb) => {
              return (!id.includes(Number(qb.mqid)))
            }

          );
          setAddedQuestions((addedQuestion) => [
            ...addedQuestion,
            ...newAddQuestion,
          ]);
          setSuggestionQuestions(newSuggestedQuestions);
          // console.log("deleted");
        }
      }).catch(error => {
        setProgress(100)
        toast(error.message, {})
      });
    } catch (error) {
      setProgress(100)
      toast(error.message)
    }
  };

  // ====++++=== this function is for publish 
  const handlePublish = async () => {

    setProgress(10)
    let formData = new FormData();
    formData.append("quiz_id", params.quizId);
    try {
      const csrf = await axiosClient.get("sanctum/csrf-cookie").then((res) => {
        axiosClient.post(
          "api/quiz/publish", formData)
          .then(res => {
            setProgress(95)
            // console.log('quiz published')

            if (res.data.success == true) {
              toast("Quest published successfully")
              setProgress(100)
              navigate('../professor/quiz/manage')
            }
            // console.log('quiz published')

          }).catch(error => {
            setProgress(100)
            console.log('something went wrong')
          })
      })

    } catch (error) {
      setProgress(100)
      console.log(error)

    }

  }


  // ++++====+++
  //this function will add all selected questions at once

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);

    if (addedQuestions.length === 0) {
      if (suggestionQuestions.length >= addedQuestions.length) {
        setNeededQuestionSelected(suggestionQuestions.slice(0, selectedQB.totQty));
      } else {
        setNeededQuestionSelected(suggestionQuestions);
      }
    } else {
      let remainingQuestion = selectedQB.totQty - addedQuestions.length;
      setNeededQuestionSelected(suggestionQuestions.slice(0, remainingQuestion));
    }


  };

  useEffect(() => {
    setIsCheck(neededQuestionSelected.map((li) => Number(li.mqid)));
    if (!isCheckAll) {

      setIsCheck([]);
    }
  }, [neededQuestionSelected]);

  const handleClick = e => {
    const { id, checked } = e.target;
    // console.log(isCheck)
    setIsCheck([...isCheck, Number(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== Number(id)));
    }
  };

  //this functions do handle change to question banks dropdown change.
  const handleGetQBQuestions = (e) => {
    getQuestionBanks(
      "api/quiz/addQuestions"+"/manually/" + params.quizId + "?questionBankId=" + e.target.value, false
    );
    //get the current selected question bank
    setSelectedQB(questionBanks.find(qb => qb.id == e.target.value));
    // console.log(selectedQB);
  }

  // console.log('total amount of question', totalAmountOfQuestion)
  // console.log('total quiz question', totQuizQuestionCount)
  return (
    <div className="choose_q_manually-container">
      <Header
        totRecords={totRecords}
        setPagination={setPagination}
        setSortBy={setSortBy}
        setSearch={setSearch}
      />
      <div className="sub-header">
        <div className="sub-header-left">
          <p>Choose Questions Manually</p>
          <span>Selected Question</span>
        </div>
        <div className="sub-header-right">
          <div className="question-recorded">
            {(addedQuestions.length < totQuizQuestionCount) ? (<span className="hin">you can add {(selectedQB) && selectedQB.totQty - addedQuestions.length} more question </span>) : 'You have added maximum number of questions'}
            <span>{addedQuestions.length}</span> <span>/</span> <span>{(selectedQB) && selectedQB.totQty}</span>
          </div>
          <select name="" id="" className="dropdown dropdown-btn" onChange={handleGetQBQuestions}>
            {questionBanks && questionBanks.map(qb => {
              return (
                <option value={qb.id} className="dropdown-item">{qb.name}</option>
              )
            })}
          </select>
          <div>
            {/* disabled= {(totQuizQuestionCount < addedQuestions.length) && ('true')} */}
            {/* className={ addedQuestions.length>=selectedQB.totQty? "disabled select-all"  : "select-all"} */}

            <Link className={totalAmountOfQuestion !== totQuizQuestionCount ? "disabled btn btn-primary" : "btn btn-primary"}

              onClick={handlePublish}>

              Publish

            </Link>

           
          </div>
        </div>
      </div>
      <div className="choose_q_manually-wrapper">
        <div className="first-question">
          {addedQuestions &&
            addedQuestions.map((aq, index) => {
              return (
                <div className=" question-wrap" key={index + 10}>
                  <span className="question-number">{++index}</span>
                  <p className="question-text">{aq.question_text}</p>
                  <span
                    className="remove-btn"
                    onClick={(e) => handleDelete(e, Number(aq.mqid))}
                  >
                    <div className="fa fa-close"></div>
                  </span>
                </div>
              );
            })}
        </div>
        <div className="selectall_n_checkall">
          <div className={addedQuestions.length >= selectedQB.totQty ? "disabled select-all" : "select-all"} onClick={e => handleAdd(e, isCheck)}>
            <span>+</span>
            <span>Add Selected Questions</span>
          </div>
          <div className="check-all">
            <span>Select all</span>
            <span>
              <input
                type="checkbox"
                className={addedQuestions.length >= selectedQB.totQty ? "disabled question-check" : "question-check"}
                name="selectAll"
                id="selectAll"

                onChange={e => handleSelectAll(e)}
                checked={isCheckAll}
              />
            </span>
          </div>
        </div>
        <div className="all-questions">
          {suggestionQuestions &&
            suggestionQuestions.map((sq, index) => {
              return (
                <div className=" question-wrap" key={index + 30}>
                  <span className="question-number">{++index}</span>
                  <p className="question-text">{sq.question_text}</p>
                  <span>
                    <input
                      key={index + 60}
                      type="checkbox"
                      name={sq.mqid}
                      id={sq.mqid}
                      value={sq.mqid}
                      onChange={handleClick}
                      checked={isCheck.includes(Number(sq.mqid))}
                      // disabled={addedQuestions.length>=selectedQB.totQty?true:false}
                      className={addedQuestions.length >= selectedQB.totQty ? "disabled" : ""}
                    />
                  </span>
                  <span
                    // className="add-btn"
                    className={addedQuestions.length >= selectedQB.totQty ? "add-btn disabled" : "add-btn"}
                    onClick={(e) => handleAdd(e, [Number(sq.mqid)])}

                  >
                    <div
                      // className="  fa fa-plus"
                      className={addedQuestions.length >= selectedQB.totQty ? "fa fa-plus disabled" : "fa fa-plus"}

                    >

                    </div>
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ChooseManually;
