const regex = {
  email: new RegExp(
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
  ),
  number: new RegExp("^[0-9]+$"),
};

export class Validators {
  /**
   *
   * @param {string} value
   * @param {string} message
   * @returns {boolean} error
   */
  static email(value, message) {
    if (value) {
      const result = regex.email.test(value);
      if (!result) return { error: true, message:message };
    }
  }
  /**
   *
   * @param {string} value
   * @param {string} message
   * @returns {boolean} error,message
   */

  static required(value, message) {
    if (!value || !value.toString().trim().length) {
      return { error: true, message:message };
    }
    return false;
  }
  /**
   *
   * @param {*} value
   * @param {*} message
   * @returns
   */
  static number(value, message) {
    const length = value ? value.toString().trim().length : 0;
    if (length > 0) {
      const result = regex.number.test(value);
      if (!result) {
        return { error: true, message:message };
      }
    }
  }
}
export const validateInput = (validators, value) => {
  if (validators && validators.length) {
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i].check(value, validators[i].message);
      if (error) {
        return error;
      }
    }
  }
  return false;
};
