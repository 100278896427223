import axios from "axios";

const axiosClient = axios.create({
    baseURL:"https://app.quizinno.com"
});
axiosClient.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axiosClient.defaults.headers.post['Accept']       = 'application/json';
axiosClient.defaults.withCredentials = true;
axiosClient.interceptors.request.use((config)=>{
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
})

axiosClient.interceptors.request.use(
(response)=>{
    //when resolved
    return response
},
(error)=>{
    const {response} = error;
    if(response.status == 401){
        localStorage.removeItem('token');
    }
})

export default axiosClient