import { createContext, useContext, useState } from "react";
const StateContext = createContext({
    user: null,
    token: null,
    progress:0,
    setProgress:()=>{},
    setUser: ()=>{},
    setToken: ()=>{}
});

export const ContextProvider = (
    {children})=>{
        const [user, _setUser] = useState(JSON.parse(localStorage.getItem('user_info')));
        const [token, _setToken] = useState(localStorage.getItem('token'));
        const [progress, setProgress] = useState(0);
        
        const setToken = (token)=>{
            _setToken(token)
            if(token){
                localStorage.setItem('token',token); 
            }else{
                localStorage.removeItem('token')
            } 
        }
        const setUser = (user)=>{
            _setUser(user)
            if(user){
                localStorage.setItem('user_info',JSON.stringify(user)); 
            }else{
                localStorage.removeItem('user_info')
            } 
        }
    return (
     <StateContext.Provider value={{
        user,token,setToken,setUser,progress,setProgress
     }}>
        {children}
     </StateContext.Provider>   
    )
}
export const useStateContext = ()=>useContext(StateContext);