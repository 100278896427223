import React from "react";
import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleDoubleRight,
  FaAngleRight,
} from "react-icons/fa";
import { BsFillCaretDownFill } from "react-icons/bs";
import "./choosequestions.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axiosClient from "./../../../axios-client";
import { useStateContext } from "../../../contexts/ContextProvider";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Header from "../../../components/header/Header";
const ChooseAutomatic = () => {
  const { setProgress } = useStateContext(); 
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [sortBy, setSortBy] = useState();
  const [search, setSearch] = useState();
  const [totRecords, setTotRecords] = useState(1);
  const [pagination, setPagination] = useState({
    pageNo: 1,
    perPage: 20,
  });
  const params = useParams();
  const navigate = useNavigate();
  // console.log(params);
  const handleDelete = async (e, id) => {
    setProgress(10);
    // await swal({
    //   title: "Are you sure you want to delete?",
    //   text: "Once deleted, you will not be able to recover",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // }).then(async (willDelete) => {
    //   if (willDelete) {
        try {
          await axiosClient
            .get(`api/quiz/question/delete`, {
              params: { quizId: params.quizId, questionId: id },
            })
            .then((res) => {
              if (res.data.success == true) {
                const newQuestions = quizQuestions.filter(
                  (qb) => qb.mqid != id
                );
                res.data.data.newQuizQuestion
                  ? setQuizQuestions((prevQuizQuestions) => [
                    { ...res.data.data.newQuizQuestion },
                      ...newQuestions,
                    ])
                  : setQuizQuestions(newQuestions);
                toast("Deleted successfully.", {});
              } else {
                toast("Not deleted.", {});
              }
              setProgress(100);
            })
            .catch((error) => {
              setProgress(100);
              toast(error.message, {});
            });
        } catch (error) {
          setProgress(100);
          toast(error.message, {});
        }
      }
      // setProgress(100);
    // );
    //==================================================================//
  // };
  async function getQuizQuestion(url = "", isQBChange = true) {
    setProgress(10);
    try {
      const res = await axiosClient.get(url, {
        params: {
          pageNo: pagination.pageNo,
          perPage: pagination.perPage,
          search: search,
          sortBy: sortBy,
        },
      });
      setProgress(20);
      if (res.data.success == true) {
        setProgress(80);
        // console.log((res.data.data.totRecords))
        setQuizQuestions(res.data.data.addedQuestions);
        setTotRecords((res.data.data.totRecords));
        setProgress(100);
      }
    } catch (error) {
      toast(error.message, {});
      setProgress(100);
    }
  }
  useEffect(() => {
    getQuizQuestion("api/quiz/addQuestions"+"/automatic/" + params.quizId);
  }, [pagination]);

  // ====++++=== this function is for publish
  const handlePublish = async () => {
    setProgress(10);
    let formData = new FormData();
    formData.append("quiz_id", params.quizId);
    try {
      const csrf = await axiosClient.get("sanctum/csrf-cookie").then((res) => {
        axiosClient
          .post("api/quiz/publish", formData)
          .then((res) => {
            setProgress(95);
            // console.log('quiz published')

            if (res.data.success == true) {
              toast("Quest published successfully");
              setProgress(100);
              navigate("../professor/quiz/manage");
            }
            // console.log('quiz published')
          })
          .catch((error) => {
            setProgress(100);
            console.log("something went wrong");
          });
      });
    } catch (error) {
      setProgress(100);
      console.log(error);
    }
  };

  // ++++====+++
  return (
    <div className="choose_q_manually-container">
      <Header
        totRecords={totRecords}
        setPagination={setPagination}
        setSortBy={setSortBy}
        setSearch={setSearch}
      />
      <div className="sub-header">
        <div className="sub-header-left">
          <p>Choose Questions Automatic</p>
          <span>Selected Question</span>
        </div>
        <div className="sub-header-right">
          
          <div>
            <Link className="btn btn-primary" onClick={handlePublish}>
              Publish
            </Link>
            {/* <a className="btn btn-primary" onClick={handlePublish}>
            </a> */}
          </div>
        </div>
      </div>
      <div className="choose_q_manually-wrapper">
        <div className="all-questions">
          {quizQuestions &&
            quizQuestions.map((que, index) => {
              return (
                <div className=" question-wrap" key={que.mqid}>
                  <span className="question-number">{++index}</span>
                  <p className="question-text">{que.question_text}</p>
                  <span
                    className="add-btn"
                    onClick={(e) => handleDelete(e, que.mqid)}
                  >
                    <i className="fa fa-close"></i>
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ChooseAutomatic;
