import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosClient from "./../../axios-client";
import Select from "react-select";
import { useStateContext } from "../../contexts/ContextProvider";
import { toast } from "react-toastify";
import { createQuestionBankValidation } from "../../schemas";
import { useFormik } from "formik";

const CreateQuestionBank = ({ setToggleQB }) => {
  const { setProgress } = useStateContext();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    async function getQBCategories(url = "") {
      setProgress(10);
      try {
        const res = await axiosClient
          .get(url)
          .then((res) => {
            setProgress(20);
            if (res.data.success == true) {
              setProgress(90);
              let allCategory = res.data.data.categories;
              let modifiedCategoy = allCategory.map((category) => {
                let item = {
                  value: category.id,
                  label: category.category_name,
                };
                return item;
              });
              setCategories(modifiedCategoy);
              setProgress(100);
            }
          })
          .catch((err) => {
            setProgress(100);
          });
      } catch (error) {
        setProgress(100);
      }
    }
    getQBCategories("api/questionbank/create/getQBcategories");
  }, []);

  const initialValues = {
    name: "",
    category_id: "",
    qbImg: "",
  };

  const {
    values,
    errors,
    handleBlur,
    touched,
    isValid,
    setSubmitting,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: createQuestionBankValidation,
    onSubmit: async (values, action) => {
      setProgress(10);
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("category_id", values.category_id.value);
      formData.append("qbImg", values.qbImg);

      // formData.forEach((value,key)=>{
      //   console.log(key + ' : ' + value)

      // })
      setProgress(20);

      // sending the data using axiosClient
      try {
        const csrf = await axiosClient
          .get("sanctum/csrf-cookie")
          .then((res) => {
            setProgress(30);
            axiosClient
              .post("api/questionbank/create", formData)
              .then((res) => {
                if (res.data.success == true) {
                  setProgress(90);
                  toast("New question bank created.", {});
                  setToggleQB(false);
                  setSubmitting(false);
                  return navigate("../professor/questionbank/manage", {
                    replace: true,
                  });
                }
              })
              .catch((err) => {
                if (err.response.status == 422)
                  toast("Failure,Validation error", {});
                setProgress(100);
                setSubmitting(false)
              });
          });
      } catch (error) {
        toast(error.message, {});
        setProgress(100);
        setSubmitting(false)

      }

      action.resetForm();
    },
  });

  /**
   *
   * @param {*} value
   */
  // console.log(categories);
  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <div className="alert display-none">
        <p>
          Your account verification is under process.it will take 1-2 business
          days
        </p>
        <a href="#!" className="btn">
          Verify
        </a>
      </div>
      <p className="welcome">Create Question Bank</p>
      <div className="row">
        <div className="right-form-group">
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Name</label>
              {errors.name && touched.name ? (
                <span className="error">{errors.name}</span>
              ) : null}
            </div>
            <div>
              <input
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Question Bank Name"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Category</label>
              {errors.category_id && touched.category_id ? (
                <span className="error">{errors.category_id}</span>
              ) : null}
            </div>

            <Select
              onBlur={handleBlur}
              value={values.category_id}
              options={categories}
              isClearable
              onChange={(selectedOption) => {
                setFieldValue("category_id", selectedOption);
              }}
              placeholder="Please Select the category"
            />
          </div>
        </div>

        <div className="upload-profile">
          <label>Question Bank Image</label>
          <label htmlFor="qbImg" className="upload-img-wrap">
            {values.qbImg && (
              <img
                src={
                  values.qbImg != "" ? URL.createObjectURL(values.qbImg) : ""
                }
                alt=""
              />
            )}
            <span>
              <i className="fa fa-camera"></i>
            </span>
          </label>
          <input
            type="file"
            name="qbImg"
            id="qbImg"
            onChange={(e) => setFieldValue("qbImg", e.target.files[0])}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="info-text-question-bank">
        <p>
          Note:{" "}
          <span>
            if you could not find your desired category please keep it blank,
            the system will generate a new category based on your question bank
            name
          </span>
        </p>
      </div>
      <div className="button-wrap">
        <button disabled={!isValid} type="submit" className="bg-primary">
         {isSubmitting ? "please wait..." : "Save"}
        </button>
      </div>
    </form>
  );
};

export default CreateQuestionBank;
