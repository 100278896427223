import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosClient from "./../../axios-client";
import "./createquiz.css";
import Constants from "../../Constants/Constants";
import { useStateContext } from "../../contexts/ContextProvider";
import { updateQuizValidation } from "../../schemas";
import { useFormik } from "formik";
import Select from "react-select";


const UpdateQuiz = () => {
  const {setProgress} = useStateContext()
  const APP_URL = Constants.APP_URL;
  const navigate = useNavigate();
  const params = useParams();




  const initialValues ={
    name: "",
    timeLimit: "",
    price: "",
    quizDescription: "",
    privacy: "",
    password: "",
    quizImage: "",

  }

  const {
    values,
    setValues,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setSubmitting,
    isSubmitting,
    touched,
    isValid

  }=useFormik({
    initialValues:initialValues,
    validationSchema:updateQuizValidation,
    onSubmit: async (values,action)=>{
      // console.log(values)
      setProgress(10)
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("timeLimit", values.timeLimit);
      formData.append("price", values.price);
      formData.append("quizDescription", values.quizDescription);
      formData.append("privacy", values.privacy);
      formData.append("password", values.password);
      formData.append("quizImg", values.quizImage);
      try {
        const csrf = await axiosClient
          .get("sanctum/csrf-cookie")
          .then((res) => {
            setProgress(40);
            axiosClient
              .post("api/quiz/update/"+ parseInt(params.quizId) , formData)
              .then((res) => {
                if (res.data.success == true) {
                  setProgress(90);
                  // setToggleQB(false);
                  setProgress(100);
                  setSubmitting(false);
                  console.log(res.data)
                  return navigate("../professor/quiz/manage");
                }
              })
              .catch((err) => {
                if (err.response.status == 422)
                  // toast("Failure,Validation error", {});
                setProgress(100);
                setSubmitting(false);
              });
          });
      } catch (error) {
        // toast(error.message, {});
        setProgress(100);
        setSubmitting(false);
      }

    }

  })
  
   //fetch the question data 
   async function  getQuiz(url=""){
    setProgress(10)
    try {
      const res =  await axiosClient.get(url);
      // console.log(res)      
      if(res.data.success == true){
        console.log(res.data.data)
        setProgress(95)
        setValues({
          name:res.data.data.quizInfo.name,
          timeLimit:res.data.data.quizInfo.time_constraint,
          price:res.data.data.quizInfo.price,
          quizDescription:res.data.data.quizInfo.description,
          privacy:res.data.data.quizInfo.privacy,
          password:res.data.data.quizInfo.password,
          quizImage:res.data.data.quizInfo.image_url

        })
      //  setQuizData({...setQuizData,
      //   ['name']:res.data.data.quizInfo.name,
      //   ['timeLimit']:res.data.data.quizInfo.time_constraint,
      //   ['score']:res.data.data.quizInfo.total_score,
      //   ['noOfQuestions']:res.data.data.quizInfo.total_question,
      //   ['price']:res.data.data.quizInfo.price,
      //   ['date']:res.data.data.quizInfo.quiz_date,
      //   ['quizDescription']:res.data.data.quizInfo.description,
      //   ['privacy']:res.data.data.quizInfo.privacy,
      //   ['quizImage']:res.data.data.quizInfo.image_url
      // })
      //Now add all added question banks
      // let tempQuizQBList = qbLists.filter(qqb=>{
      //   var flag = false;
      //   JSON.parse(res.data.data.quizInfo.question_banks_ids).forEach(qb => {
      //       flag = (qqb.id == qb.id)? true:"";
      //   });
      //   return flag;
      // })
        // setQuizQBList(tempQuizQBList);
        setProgress(100)
      }
  } catch (error) {
    setProgress(100)
      console.log(error)
  }
  
  
  }
  useEffect( ()=>{
    getQuiz("api/quiz/edit/"+params.quizId);
  },[]);
  /**
   *
   * @param {*} value
   */
  // const handleChange = (event) => {
  //   setQuizData((e) => ({ ...e, [event.target.name]: event.target.value }));
  //   //handle error

  //   seterrors((e) => ({
  //     ...e,
  //     [event.target.name]: validateInput(
  //       validators[event.target.name],
  //       event.target.value
  //     ),
  //   }));
  //   //console.log(value)
  // };
  // const handleSubmit = async (e,mode) => {
  //   setProgress(10)
  //   e.preventDefault();
  //   let formData = new FormData();
  //   formData.append("name", quizData.name);
  //   formData.append("timeLimit", quizData.timeLimit);
  //   formData.append("score", quizData.score);
  //   formData.append("noOfQuestions", quizData.noOfQuestions);
  //   formData.append("price", quizData.price);
  //   formData.append("date", quizData.date);
  //   formData.append("quizDescription", quizData.quizDescription);
  //   formData.append("privacy", quizData.privacy);
  //   formData.append("password", quizData.password);
  //   /**
  //    * here filter the question banks and send only those which has the Qty more than 0
  //    * map and make new array having only required property as json but not all.
  //    */
  //   let quizQB = quizQBLists.filter(qq=>(qq.totQty>1));
  //   quizQB = quizQB.map((qb,index)=>({id:qb.id,name:qb.name,totQty:qb.totQty}))
  //   formData.append("questionBanks", JSON.stringify([...quizQB]));
  //   formData.append("quizImg", quizData.quizImage);
  //   setProgress(20)
  //   // sending the data using axiosClient
  //   const url = (mode === 'manually')? "api/quiz/create/manually":"api/quiz/create/automatic";
  //   const csrf = await axiosClient.get("sanctum/csrf-cookie").then((res) => {
  //     axiosClient
  //       .post(url, formData)
  //       .then((res) => {
  //         if (res.data.success == true) {
  //           setProgress(100)
  //           //set questions and send to next page
  //           if(mode == 'manually'){
  //             return navigate("../professor/quiz/chooseQuestions/manually/"+res.data.data.quizId,{state:{questions:res.data.data.quizQuestions}});
  //           }else{  
  //             setQuizQuestionsState(res.data.data.quizQuestions);
  //             // return navigate("../professor/quiz/chooseQuestions/automatic/"+ res.data.data.quizId,{state:{questions:res.data.data.quizQuestions}});
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         setProgress(100)
  //         console.log(err);
  //       });
  //   });

  // };
  //this function will handle question banks to be added into quiz
  // const handleSetQuestionBanks = async(e) => {
  //   // var newBank = { 'queBankId': e.target.value, 'queBankName': e.target.innerText, 'totQty': "" };
  //   // // quizData.questionBank.push(newBank);
  //   // setQuizQBList(quizQBLists => [...quizQBLists, newBank]);
  //   if(remainingQuestion > 0){
  //     const newQBLists = qbLists.filter((qb) => (qb.id != `${e.target.value}`));
  //   const newQuizQBLists = qbLists.filter((qb) => (qb.id == `${e.target.value}`));
  //   //add default total question into it.
  //   setAddedQuestion(remainingQuestion);
  //   setRemainingQuestions(quizData.noOfQuestions - addedQuestions);
  //   newQuizQBLists[0].totQty = addedQuestions;
  //   setQBLists([...newQBLists])
  //   setQuizQBList((qql) => ([...qql, ...newQuizQBLists]))
  //   // console.log(newQBLists)
  //   console.log("remain"+remainingQuestion);
  //   }

  // }
  // async function getQuestionBanks(url = "") {

  //   try {
  //     const res = await axiosClient.get(url);
  //     // console.log(res)
  //     if (res.data.success == true) {

  //       setQBLists(res.data.data.questionBanks);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // useEffect(() => {

  // }, [quizData])
  // useEffect(() => {
  //   getQuestionBanks("api/questionbank/manage");
  // }, []);
  //this function add no of question to be used in a quiz
  // const handleAddTotQue = (e, queBankId) => {
  //   const index = quizQBLists.findIndex(object => {
  //     return object.id == queBankId;
  //   }); // 1

  //   if (index !== -1) {
  //     quizQBLists[index].totQty = parseInt(e.target.value);
  //     //now subtract this value from remainingQuestion state
  //     (quizQBLists[index].totQty > parseInt(e.target.value))?      setRemainingQuestions(eval(remainingQuestion + (quizQBLists[index].totQty-parseInt(e.target.value))))
  //     :setRemainingQuestions(eval(remainingQuestion - (parseInt(e.target.value)-quizQBLists[index].totQty)))
  //     ;
  //   }
  // };
  // const handleRemoveQB = (e,id) => {
  //   // const newQuestionBanks = quizQBLists.filter((qb) => qb.id != id);
  //   // setQuizQBList(newQuestionBanks);
  //   // console.log("deleted");
  //   const newQBLists = quizQBLists.filter((qb) => (qb.id == id));
  //   const newQuizQBLists = quizQBLists.filter((qb) => (qb.id != id));
  //   setRemainingQuestions(remainingQuestion - parseInt(newQuizQBLists[0].totQty));
  //   setQBLists((qql) => ([...qql, ...newQBLists]))
  //   setQuizQBList([...newQuizQBLists])

  // };

  const time_limit = [
    { value: "open", label: "open" },
    { value: "limit", label: "limit" },
  
  ];

  const price_limit = [
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
  ];

  const privacy_limit = [
    {
      value:"public", label:"public"
    },
    {
      value:"private", label:"private"
    }
  ]
  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <p className="create-quiz" onClick={e=>{navigate("professor/quiz/chooseQuestions/"+(1 == 1)?'manually':'automatic'+"/" + 2)}}>Create Quiz</p>
      <div className="cq-container">
        <div className="cq-left">
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Name</label>
              {errors.name && touched.name ? (
                <span className="error">{errors.name}</span>
              ) : null}
            </div>
            <input
              type="text"
              name="name"
              dir="auto"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter Your Quiz Name"
            />
          </div>
          <div className="form-group-password">
            {/* <div className="form-group ">
              <div className="form-label">
                <label htmlFor="name">Number of Questions</label>
              </div>
              <input
                className={errors.noOfQuestions.message && "error"}
                type="text"
                name="noOfQuestions"
                value={quizData.noOfQuestions}
                onChange={(e) => {handleChange(e);setRemainingQuestions(parseInt(e.target.value))}}
                placeholder="Enter noOfQuestions"
              />
              <div className="form-error">
                <span className="error">
                  {errors.noOfQuestions.message && errors.noOfQuestions.message}
                </span>
              </div>
            </div> */}
            {/* <div className="form-group">
              <div className="form-label">
                <label htmlFor="name">Score</label>
              </div>
              <input
                className={errors.score.message && "error"}
                type="text"
                name="score"
                value={quizData.score}
                onChange={(e) => handleChange(e)}
                placeholder="score"
              />
              <div className="form-error">
                <span className="error">
                  {errors.score.message &&
                    errors.score.message}
                </span>
              </div>
            </div> */}
          </div>
          <div className="form-group-password">
            <div className="form-group ">
              <div className="form-label">
                <label htmlFor="name">Time Limit</label>
              </div>
             
             <Select
              onBlur={handleBlur}
              value={time_limit.find(
                (option) => option.value == values.timeLimit
              )}
              options={time_limit}
              onChange={(selectedOption) => {
                setValues((prevState) => ({
                  ...prevState,
                  timeLimit: selectedOption.value,
                }));
              }}
              placeholder="Please Select the Time limit"
            />
              <div className="form-error">
                <span className="error">
                  
                </span>
              </div>
            </div>
            <div className="form-group">
              <div className="form-label">
                <label htmlFor="name">Price</label>
              </div>
              <Select
              onBlur={handleBlur}
              value={price_limit.find(
                (option) => option.value == values.price
              )}
              options={price_limit}
              onChange={(selectedOption) => {
                setValues((prevState) => ({
                  ...prevState,
                  price: selectedOption.value,
                }));
              }}
              placeholder="Please Select the Price"
            />
          
            </div>
           
          </div>
        
          <div className="score">
            <div className="form-label">
              <label htmlFor="">Quiz Description</label>
              <span className="error">
                {/* {errors.quizDescription.message && errors.quizDescription.message} */}
              </span>
            </div>
            <textarea dir="auto" onChange={handleChange} onBlur={handleBlur} name="quizDescription" value={values.quizDescription} id="" cols="30" rows="9"></textarea>
          </div>
        </div>
        <div className="cq-right">
          <div className="row">
            <div className="left-form-group">
              <div className="form-group">
                <div className="form-label">
                  <label htmlFor="name">Privacy</label>            
                </div>
                <Select
              onBlur={handleBlur}
              value={privacy_limit.find(
                (option) => option.value == values.privacy
              )}
              options={privacy_limit}
              onChange={(selectedOption) => {
                setValues((prevState) => ({
                  ...prevState,
                  privacy: selectedOption.value,
                }));
              }}
              placeholder="Please Select the Privacy"
            />
          
              </div>
              <div className="form-group">
                <div className="form-label">
                  <label htmlFor="name">Password</label>

                </div>
                <input
                  type="text"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Your password"
                  autoFocus=""
                  label={"password"}
                />
              </div>
            </div>
            <div className="upload-profile">
              <label >Quiz Image</label>
              <label htmlFor="quizImage" className="upload-img-wrap" >
                <img src={
                values.quizImage != null
                  ? typeof values.quizImage != "string"
                    ? URL.createObjectURL(values.quizImage)
                    : `${APP_URL}/storage/thumbnail_images/quiz/` +
                      values.quizImage
                  : ""
              } alt="" /> <span><i className="fa fa-camera"></i></span>
              </label>
              <input type="file" name="quizImage" id="quizImage" 
              onChange={e => setValues((prevState)=>({ ...prevState, quizImage: e.target.files[0] }))} />
            </div>
          </div>
        
          <div className="add-question-btns">
          <button type="submit" disabled={!isValid} className="bg-primary">
          {isSubmitting ? "please wait..." : "Update"}
        </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateQuiz;
