import React, { useState } from "react";
import { useFormik } from "formik";

import "./ChangePassword.css";
import {changePasswordValidation} from '../../schemas'
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import { toast } from "react-toastify";

function ChangePassword() {

  const {setProgress}= useStateContext();
  const [showPassword, setShowPassword] = useState({
    password: false,
    cpassword: false,
  });
  const toggleShowPassword = (parameter) => {
    if(parameter==="cpassword"){

      setShowPassword({ ...showPassword, cpassword: !showPassword.cpassword });
    }
    if(parameter==="password"){
      setShowPassword({...showPassword,password:!showPassword.password})
    }
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema:changePasswordValidation,
    onSubmit: async(values,action) => {
      setProgress(10);
      let formData = new FormData();
      formData.append('password',values.newPassword)
      setProgress(20)
      try {
        const res = await axiosClient.post('api/professor/profile/changePassword',formData)
        .then((res)=>{
          if(res.data.success==true){
            setProgress(90)
            toast('Password Successfully changed',{});
            action.resetForm()
            formik.setSubmitting(false)

          }

        })
        .catch((error)=>{
          if(error.response.status==422){
            toast("password change Failed, please try later",{});
            setProgress(100)
            formik.setSubmitting(false)
          }
        })
        
      } catch (error) {
        toast(error.message,{})
        setProgress(100);
        formik.setSubmitting(false)

        
        
      }
      setProgress(100)
      formik.setSubmitting(false)
      
   
    },
  });
 

  return (
    <div className="change-password-container">
      <h2 style={{ textAlign: "left" }}>Change Password</h2>
      <form onSubmit={formik.handleSubmit} className="register-form">
        <div className="form-group" style={{ textAlign: "left" }}>
          <label htmlFor="new-password">New Password:</label>
          <div className="input-with-reveal">
            <input
              className={
                formik.touched.newPassword && formik.errors.newPassword
                  ? "form-control is-invalid"
                  : "form-control"
              }
              // type={showPassword.passwrod ? "text" : "password"}
              type={showPassword.password ? "text" : "password"}
              id="new-password"
              {...formik.getFieldProps("newPassword")}
            />
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <div className="invalid-feedback">
                {formik.errors.newPassword}
              </div>
            ) : null}
            <span
              className="input-group-addon change_password-reveal"
              onClick={toggleShowPassword.bind(this,"password")}
            >
              <i
                className={
                  showPassword.password ? "fa fa-eye-slash" : "fa fa-eye"
                }
              ></i>
            </span>
          </div>
        </div>
        <div className="form-group " style={{ textAlign: "left" }}>
          <label htmlFor="confirm-password">Confirm Password:</label>

          <div className="input-with-reveal">
            <input
              className="form-control"
              type={showPassword.cpassword ? "text" : "password"}
              id="confirm-password"
              {...formik.getFieldProps("confirmPassword")}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <span className="invalid-feedback">
                {formik.errors.confirmPassword}
              </span>
            ) : null}

            <span
              className="input-group-addon change_password-reveal"
              onClick={toggleShowPassword.bind(this,"cpassword")}
              name="cpassword"
            >
              <i
                className={
                  showPassword.cpassword ? "fa fa-eye-slash" : "fa fa-eye"
                }
              ></i>
            </span>
          </div>

          {/* <i className="fa fa-eye-slash"></i> */}
        </div>
        <button type="submit" 

        disabled={!formik.isValid}
        
        className="btn btn-primary btn-center">
          {formik.isSubmitting ? 'please wait...' : "Change Password"}
          
        </button>
      </form>
    </div>
  );
}

export default ChangePassword;
