
import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleDoubleRight,
  FaAngleRight,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import "./header.css"
const Header = ({totRecords, setPagination,setSortBy,setSearch}) => {
    const [searchData, setSearchData] = useState("");
    const [sort, setSort] = useState();
    const [perPage, setPerPage] =useState(20);
    const [pageNo,setPageNo] = useState(1);
    const [pages,setPages]= useState([]);
    useEffect(()=>{
    setPagination(p=>(
      {...p,
        ['perPage']:perPage,
        ['pageNo']:pageNo}
      ));
  },[perPage,pageNo,searchData,sort]);
  useEffect(()=>{
    var temp = [];
    var totpages = Math.ceil(totRecords/perPage);
    for(var i=1 ;i<= totpages;i++){
        temp.push(i);
        setPages(temp)
    }
    console.log(pages);
  },[totRecords,perPage]);
  const handleChange = (e) => {

  };
  //set the pagination 
  return (
    <header>
      <div className="tot-rows">
        <div>
          <span>
            <FaAngleDoubleLeft />
          </span>
          <span>
            <FaAngleLeft />
          </span>
        </div>
        <select name="pageNo" className="dropdown-btn dropdown" id="" onChange={e=>setPageNo(parseInt(e.target.value))}>
          {pages && pages.map(element => 
            <option value={element} key={element}>{element}</option>
          )}
        </select>
        <div>
          <span>
            <FaAngleRight />
          </span>
          <span>
            <FaAngleDoubleRight />
          </span>
        </div>
      </div>
      <div className="perpage">
        <span>per page</span>
        <select name="perPage" className="dropdown-btn dropdown" id="" value={perPage} onChange={e=>{handleChange(e);setPerPage(parseInt(e.target.value))}}>
          <option value="5" selected>5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
      </div>
      <div className="sortby">
        <span>sort by</span>
        <select name="sortBy" className="dropdown-btn dropdown" id="" value={sort} onChange={e=>{setSort(e.target.value);setSortBy(e.target.value)}}>
          <option value="desc" selected>DESC</option>
          <option value="asc" >ASC</option>
        </select>
      </div>
      <div className="search">
        <input type="search" placeholder="Search..." value={searchData} onChange={e=>{setSearch(e.target.value);setSearchData(e.target.value)}} />
      </div>
    </header>
  );
};
export default Header;
