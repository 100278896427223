import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import "./enrollment.css";
import Img from "./../../../asset/profile.jpeg";
import axiosClient from "./../../../axios-client";
import Header from "../../../components/header/Header";
import Constants from "../../../Constants/Constants";
import { useStateContext } from "../../../contexts/ContextProvider";
import moment from "moment";
const Enrollment = () => {
  const { setProgress } = useStateContext();
  const APP_URL = Constants.APP_URL;
  const navigate = useNavigate();
  const params = useParams();
  const [enrollments, setEnrollments] = useState([]);
  const [sortBy, setSortBy] = useState();
  const [search, setSearch] = useState();
  const [totRecords, setTotRecords] = useState(1);
  const [pagination, setPagination] = useState({
    pageNo: 1,
    perPage: 20,
  });
  async function getEnrollments(url = "") {
    setProgress(10);
    try {
      const res = await axiosClient.get(url, {
        params: {
          pageNo: pagination.pageNo,
          perPage: pagination.perPage,
          search: search,
          sortBy: sortBy,
        },
      });
      setProgress(90);
      // console.log(res)
      if (res.data.success == true) {
        setEnrollments(res.data.data.enrollments);
        setTotRecords(res.data.data.totRecords);
        setProgress(100);
      }
    } catch (error) {
      setProgress(100);
      console.log(error);
    }
  }
  useEffect(() => {
    getEnrollments("api/quiz/enrollment/manage/" + params.quizId);
  }, [pagination]);
  return (
    <div className=" enrollment-container">
      <Header
        totRecords={totRecords}
        setPagination={setPagination}
        setSortBy={setSortBy}
        setSearch={setSearch}
      />
      <div className="sub-header">
        <p>History Quiz Enrollments</p>
      </div>
      <div className="enrollments-wrapper">
        {enrollments &&
          enrollments.map((en) => {
            return (
              <div
                className="enrollment-wrap"
                key={en.student_id}
                onClick={(e) => {
                  return navigate("../professor/profile/view/" + en.student_id);
                }}
              >
                <div className=" enrollment-left">
                  {en.profile_image_url ? (
                    <img
                      src={
                        `${APP_URL}/storage/thumbnail_images/profiles/` +
                        en.profile_image_url
                      }
                      alt="img"
                    />
                  ) : (
                    <img
                      src={`${APP_URL}/storage/Images/profiles/avatar.png`}
                      alt="img"
                    />
                  )}

                  <div className=" enrollment-info">
                    <span>{en.username}</span>
                    <span className="date font-size-10">
                      {/* <pre>{en.created_at}</pre> */}
                      {moment(en.created_at).format("YYYY MMMM Do h:mm a")}
                    </span>
                  </div>
                </div>
                <div className=" enrollment-right"></div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Enrollment;
