import React, { useEffect } from "react";
import axiosClient from "./../../axios-client";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { BsPlusLg } from "react-icons/bs";
import "./questionbank.css";
import CreateQuestionBank from "./CreateQuestionBank";
import { useState } from "react";
import QBank from "./components/QBank";
import UpdateQuestionBank from "./components/UpdateQuestionBank";
import Header from "../../components/header/Header";
import axiosClientClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import swal from "sweetalert";
//handle click
import { toast } from "react-toastify";
const QuestionBank = () => {
  const [verified,setVerified]=useState(4);
  const { setProgress } = useStateContext();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState('desc');
  const [search, setSearch] = useState();
  const [totRecords, setTotRecords] = useState();
  const [pagination, setPagination] = useState({
    pageNo: 3,
    perPage: 20,
  });
  const [toggleQB, setToggleQB] = useState(false);
  // const [toggleThreeDot, setToggleThreeDot] = useState(false);
  const [questionBanks, setQuestionBanks] = useState([]);
  //get the questionbanks from server
  // const [loading, setLoading] = useState(true);
  const [updatebleId, setUpdatebleId] = useState(0);
  // this state is used for menu dropdown flag
  // const [flag,setFlag]=React.useState(false)

  //This function will filter the posts
  async function getQuestionBanks(url = "") {
    setProgress(10);
    try {
      const res = await axiosClientClient.get(url, {
        params: {
          pageNo: pagination.pageNo,
          perPage: pagination.perPage,
          search: search,
          sortBy: sortBy,
        },
      });
      setProgress(20);
      // console.log(res)
      if (res.data.success == true) {
        setProgress(100);
        console.log(res.data.data);
        // console.log(parseInt(res.data.data.professor_verified.is_professor_verified))
       
          setVerified(parseInt(res.data.data.professor_verified.is_professor_verified))
       
        setTotRecords(res.data.data.totRecords);
        setQuestionBanks(res.data.data.questionBanks);
        // if (res.data.data.totRecords <= 0) {
        //   toast("You have no question bank.", {});
        // }
      }
    } catch (error) {
      setProgress(100);
      toast(error.message);
    }
  }

  useEffect(() => {
    getQuestionBanks("api/questionbank/manage");
  }, [toggleQB, pagination]);
  // console.log(verified)
  //handle delete Question Bank
  const handleDelete = async (e, id) => {
    await swal({
      title: "Are you sure you want to delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setProgress(10);
        const res = await axiosClientClient
          .get(`api/questionbank/delete/${id}`)
          .then((res) => {
            setProgress(20);
            if (res.data.success == true) {
              setProgress(70);
              toast("Deleted Successfully.", {});
              const newQuestionBanks = questionBanks.filter(
                (qb) => qb.id != id
              );
              setQuestionBanks(newQuestionBanks);
              setProgress(100);
            }
          })
          .catch((error) => {
            toast("Delete faild please try again.");
          });
      } else {
        return;
      }
    });
  };
  // console.log(location.pathname)
  //any questionbank clicked from a list it will view the details
  const qbClick = (e, id) => {
    if (e.target.className !== "clickable")
      return navigate("../professor/questionbank/open/" + id);
  };
  const toggleNewQuestionBankPopup = (e, qbId) => {
    e.preventDefault();
    setToggleQB(true);
    if (e.target.id == "updateQB") {
      setUpdatebleId(qbId);
    }
  };
  return (
    <>
      {verified === 3 ? (
        <>
          {toggleQB && (
            <>
              <div
                className="dark-them"
                onClick={(e) => {
                  setToggleQB(false);
                  setUpdatebleId("");
                }}
              ></div>
              {updatebleId == "" && (
                <div className="create-new-questionbank">
                  <CreateQuestionBank setToggleQB={setToggleQB} />
                </div>
              )}
              {updatebleId && (
                <div className="create-new-questionbank">
                  <UpdateQuestionBank
                    setToggleQB={setToggleQB}
                    updatebleId={updatebleId}
                  />
                </div>
              )}
            </>
          )}
          <div className="qb-container">
            <Header
              totRecords={totRecords}
              setPagination={setPagination}
              setSortBy={setSortBy}
              setSearch={setSearch}
            />
            <div className="sub-header">
              <h3>Question Banks</h3>
              <a
                href="#"
                className="btn btn-primary btn-with-icon"
                onClick={(e) => toggleNewQuestionBankPopup(e)}
              >
                <span>Create New</span>
                <BsPlusLg />
              </a>
            </div>
            <div className="questionbanks-wrapper">
              {questionBanks &&
                questionBanks.map((qb) => {
                  // console.log(qb.id)
                  return (
                    <QBank
                      qbClick={qbClick}
                      handleDelete={handleDelete}
                      totQuestion={qb.total_question}
                      toggleNewQuestionBankPopup={toggleNewQuestionBankPopup}
                      qbImg={qb.image_url}
                      qbName={qb.name}
                      key={qb.id}
                      id={qb.id}
                    />
                  );
                })}
            </div>
          </div>
        </>
      ) : verified===2 ? (
        // <>
         navigate("../professor/profile/verification", {
        replace: true,
          })
          
        // </>
      ) : verified===1 ? (
        navigate("../professor/profile/verification", {
          replace: true,
            })
      )
      
      : verified===0 ? (
        navigate("../professor/profile/verification", {
          replace: true,
            })
      )
      
      : (
          <h3>loading...</h3> 
      )
      
      }
    </>
  );
  
    
  
};

export default QuestionBank;
