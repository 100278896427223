import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import MaleProfileAvatar from "./../../asset/avatar.jpeg";
import { BsBank } from "react-icons/bs";
import {
  // FaCoins,
  FaRegQuestionCircle,
  FaUserCircle,
  // FaUsers,
} from "react-icons/fa";
import { RiDashboardFill } from "react-icons/ri";
import { FaKey, FaCheckCircle } from "react-icons/fa";
import "./sidebar.css";

import { useStateContext } from "../../contexts/ContextProvider";
import { toast } from "react-toastify";
import Constants from "../../Constants/Constants";
import axiosClient from "../../axios-client";
import swal from "sweetalert";
const SideBar = () => {
  const { setProgress } = useStateContext();
  const APP_URL = Constants.APP_URL;
  const location = useLocation();
  const { pathname } = location;
  const { setToken, setUser, user } = useStateContext();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  // alert(pathname.sb)
  //logout
  console.log(user)
  const handleLogout = async (e) => {
    await swal({
      title: "Are you sure you want to logout?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const res = await axiosClient
            .get("api/logout")
            .then((res) => {
              setProgress(20);
              // console.log(res)
              if (res.data.success == true) {
                setToken(null);
                setUser(null);
                setProgress(100);
                return <Navigate to={"/"} />;
              } else {
                setProgress(100);
                toast("Logout failded. please try again");
              }
            })
            .catch((error) => {
              setProgress(100);
              toast(error.message, {});
            });
        } catch (error) {
          toast(error.message, {});
          setProgress(100);
        }
      }
    });
    //
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (!event.target.matches(".profile-image-sidebar")) {
        setDropdownVisible(false);
      }
    };
    window.addEventListener("click", handleClick);
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
 

  //function will navigate
  const handleNavigate = () => {};
  return (
    <aside>
      <div className="profile-wrap">
        <div className="profile">
        

          <img
            className="profile-image-sidebar profile-img"
            src={
              user.profile_image_url != null
                ? `${APP_URL}/storage/thumbnail_images/profiles/${user.profile_image_url}`
                : MaleProfileAvatar
            }
            alt=""
            width={30}
            onClick={toggleDropdown}
          />
          {dropdownVisible && (
            <div className="dropdown-sidebar "  id="myDropdown">
              <div className="dropdown-header-sidebar" >
                {/* <Link to={"professor/profile/update"} style={{ textDecoration: 'none' }}> */}
                <div className="dropdown-image-container">

                  <img
                    className="dropdown-image"
                    src={
                      user.profile_image_url != null
                        ? `${APP_URL}/storage/thumbnail_images/profiles/${user.profile_image_url}`
                        : MaleProfileAvatar
                    }
                    alt="Profile Image"
                  
                  />
                </div>
                {/* </Link> */}
                <p style={{ marginLeft: "10%" }} className="dropdown-username">
                  {user.name.substring(0, 30)}
                </p>
              </div>
              <Link
                to={"professor/profile/update"}
                style={{ paddingLeft: "15px", display: "flex", gap: "3em" }}
              >
                <span>
                  <FaUserCircle />
                </span>{" "}
                <span> Your Profile</span>{" "}
              </Link>

              <Link
                to={"professor/changePassword"}
                style={{ paddingLeft: "15px", display: "flex", gap: "3em" }}
              >
                <FaKey />
                Change Password
              </Link>

              {parseInt(user.is_professor_verified) !== 3 && (
                <Link
                  to="professor/profile/verification"
                  style={{ paddingLeft: "15px", display: "flex", gap: "3em" }}
                >
                  <span>
                    <FaCheckCircle />
                  </span>

                  <span>Verify</span>
                </Link>
              )}

              <a
                style={{ paddingLeft: "15px", display: "flex", gap: "3em" }}
                href="#"
                onClick={(e) => handleLogout(e)}
              >
                <span>
                  <i className="fa fa-sign-out"></i>
                </span>
                Log Out
              </a>
            </div>
          )}

        
          <span className="user-name">{user.name.substring(0, 30)}</span>
        </div>
      </div>
      <div className="sidemenu">
        <ul>
          <li></li>
          <li>
            
            <Link to={"professor/dashboard"} className={
                pathname == "/app/professor/dashboard" ? "sidebar-active" : ""
              }>


            <span>
                <RiDashboardFill />
              </span>
              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to={"professor/questionbank/manage"} className={
                pathname == "/app/professor/questionbank/manage"
                  ? "sidebar-active"
                  : ""
              } >
            <span>
                <BsBank />
              </span>
              <span>QuestionBank</span>
            
            </Link>
          
          </li>
          <li>
            <Link to={"professor/quiz/manage"}
            className={
                pathname == "/app/professor/quiz/manage" ? "sidebar-active" : ""
              }>
                <span>
                <FaRegQuestionCircle />
              </span>
              <span>Quiz</span>
            
            
            </Link>
         
          </li>
          {parseInt(user.user_id) === 29 && (
            <li>

              <Link to={"professor/verified"}  className={
                  pathname == "/app/professor/verified" ? "sidebar-active" : ""
                }>
              
              <span>
                  <FaUserCircle />
                </span>
                <span>Professors</span>
              </Link>

             
            </li>
          )}
         
        </ul>

     
      </div>
    </aside>
  );
};

export default SideBar;
