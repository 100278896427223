import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosClient from "./../../../axios-client";

import Constants from "../../../Constants/Constants";
import { useStateContext } from "../../../contexts/ContextProvider";
import { toast } from "react-toastify";
import Select from "react-select";
import { createQuestionBankValidation } from "../../../schemas";
import { useFormik } from "formik";

const UpdateQuestionBank = ({ setToggleQB, updatebleId }) => {
  const { setProgress } = useStateContext();
  const APP_URL = Constants.APP_URL;
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  /**
   *
   * @param {*} value
   */

  const initialValues = {
    name: "",
    category_id: "",
    qbImg: "",
  };

  const {
    values,
    errors,
    handleBlur,
    touched,
    isValid,
    isSubmitting,
    setSubmitting,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: createQuestionBankValidation,
    onSubmit: async (values, action) => {
      setProgress(10);
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("category", values.category);
      formData.append("qbImg", values.qbImg);
      setProgress(20);
      try {
        const csrf = await axiosClient
          .get("sanctum/csrf-cookie")
          .then((res) => {
            setProgress(40);
            axiosClient
              .post("api/questionbank/update/" + updatebleId, formData)
              .then((res) => {
                if (res.data.success == true) {
                  setProgress(90);
                  setToggleQB(false);
                  setProgress(100);
                  action.resetForm();
                  setSubmitting(false);
                  return navigate("../professor/questionbank/manage", {
                    replace: true,
                  });
                }
              })
              .catch((err) => {
                if (err.response.status == 422)
                  toast("Failure,Validation error", {});
                setProgress(100);
                action.resetForm();
                setSubmitting(false);
              });
          });
      } catch (error) {
        toast(error.message, {});
        setProgress(100);
        action.resetForm();
        setSubmitting(false);
      }
    },
  });

  async function getQuestionBank(url = "") {
    setProgress(10);
    try {
      await axiosClient
        .get(url)
        .then((res) => {
          if (res.data.success == true) {
            setProgress(90);
            setValues({
              name: res.data.data.question_bank.name,
              category: res.data.data.question_bank.category_id,
              qbImg: res.data.data.question_bank.image_url,
            });
          }
        })
        .catch((err) => {
          toast("Something went wrong", {});
          setProgress(100);
        });
    } catch (error) {
      toast(error.message, {});
      setProgress(100);
    }
  }
  useEffect(() => {
    getQuestionBank(`api/questionbank/edit/${updatebleId}`);
  }, []);
  //getting question bank categories when page loads
  useEffect(() => {
    async function getQBCategories(url = "") {
      setProgress(10);
      try {
        const res = await axiosClient
          .get(url)
          .then((res) => {
            setProgress(20);
            if (res.data.success == true) {
              setProgress(90);
              let allCategory = res.data.data.categories;
              let modifiedCategoy = allCategory.map((category) => {
                let item = {
                  value: category.id,
                  label: category.category_name,
                };
                return item;
              });
              setCategories(modifiedCategoy);
              setProgress(100);
            }
          })
          .catch((err) => {
            setProgress(100);
          });
      } catch (error) {
        setProgress(100);
      }
    }
    getQBCategories("api/questionbank/create/getQBcategories");
  }, []);

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <div className="alert display-none">
        <p>
          Your account verification is under process.it will take 1-2 business
          days
        </p>
        <a className="btn">Verify</a>
      </div>
      <p className="welcome">Update Question Bank</p>
      <div className="row">
        <div className="right-form-group">
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Name</label>
              {errors.name && touched.name ? (
                <span className="error">{errors.name}</span>
              ) : null}
            </div>
            <div>
              <input    
                dir="auto"
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Question Bank Name"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Category</label>
            </div>

            <Select
              onBlur={handleBlur}
              value={categories.find(
                (option) => option.value == values.category
              )}
              options={categories}
              onChange={(selectedOption) => {
                setValues((prevState) => ({
                  ...prevState,
                  category: selectedOption.value,
                }));
              }}
              placeholder="Please Select the category"
            />
          </div>
        </div>
        <div className="upload-profile">
          <label>Question Bank Image</label>
          <label htmlFor="qbImg" className="upload-img-wrap">
            {values.qbImg && (
              <img
                src={
                  values.qbImg != ""
                    ? typeof values.qbImg != "string"
                      ? URL.createObjectURL(values.qbImg)
                      : `${APP_URL}/storage/thumbnail_images/questionbank/` +
                        values.qbImg
                    : ""
                }
                alt=""
              />
            )}
            <span>
              <i className="fa fa-camera"></i>
            </span>
          </label>
          <input
            type="file"
            name="qbImg"
            id="qbImg"
            onChange={(e) => {
              setValues((prevState) => ({
                ...prevState,
                qbImg: e.target.files[0],
              }));
            }}
            onBlur={handleBlur}
          />
        </div>
      </div>

      <div className="button-wrap">
        <button type="submit" disabled={!isValid} className="bg-primary">
          {isSubmitting ? "please wait..." : "Update"}
        </button>
      </div>
    </form>
  );
};

export default UpdateQuestionBank;
