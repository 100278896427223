import './App.css';
import React from "react";
import { RouteProps } from "react-router";
import axios from 'axios';
import { useRoutes, Navigate} from "react-router-dom";
import {routes, protectedRoutes} from './routes';
import { useAuthContext } from './hooks/auth/useAuthContext';
//axios
function App() { 
 
  const routing = useRoutes(routes);
  const protectedRouting = useRoutes(protectedRoutes)
  return (
    <div className="App">
      {protectedRouting}
      {routing}
    </div>
  );
}

export default App;
