import React, { useEffect } from 'react'
import { Outlet , Navigate} from 'react-router';
import { useNavigate } from 'react-router';
import SideBar from '../SideBar/SideBar'
import { useAuthContext } from '../../hooks/auth/useAuthContext';
import './layout.css';
import { useStateContext } from '../../contexts/ContextProvider';
import LoadingBar from 'react-top-loading-bar';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
const AppLayout = () => {
  const {setProgress} = useStateContext();
  const navigate = useNavigate();
  const {user,token,progress} = useStateContext();
    if(token == null){
     return <Navigate to="/signin" />
    }
  return (
    <div className="main-container">
      <div className={`${ (progress >0 && progress <100) && "loading-them"}`}></div>
      <LoadingBar 
        color='#D20000'
        height={3}
        loaderSpeed={1000}
        progress={progress}
      />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
{/* Same as */}
      <ToastContainer />
        <div className="left">
        <SideBar />
        </div>
        <div className="right">
          <Outlet />
        </div>
    </div>
  )
}

export default AppLayout