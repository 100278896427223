import React from "react";
import { Navigate } from "react-router";
import { Signup } from "./pages";
import Dashboard from "./Pages/dashboard/Dashboard";
import ForgotPassword from "./Pages/forgot-password/ForgotPassword";
import NotFoundView from "./Pages/error/NotFoundView";
import OTP from "./Pages/otp/OTP";
import ProfessorAccountVerification from "./Pages/profile/ProfessorAccountVerification";
import ProfessorUpdateProfile from "./Pages/profile/ProfessorUpdateProfile";
import OpenProfile from "./Pages/profile/OpenProfile";
import Signin from "./Pages/sign-in/Signin";
import CreateQuestion from "./Pages/question/CreateQuestion";
import QuestionBank from "./Pages/questionbank/QuestionBank";
import CreateQuestionBank from "./Pages/questionbank/CreateQuestionBank";
import OpenQuestionBank from "./Pages/questionbank/SuggestiveQuestions";
import CreateQuiz from "./Pages/quiz/CreateQuiz";
import Quiz from "./Pages/quiz/Quiz";
import ChooseManually from "./Pages/quiz/choosequestions/ChooseManually";
import ChooseAutomatic from "./Pages/quiz/choosequestions/ChooseAutomatic";
import Result from "./Pages/quiz/Result/Result";
import OpenQuiz from "./Pages/quiz/OpenQuiz";
import Enrollments from "./Pages/quiz/enrollments/Enrollment";
import SuggestiveQuestion from "./Pages/questionbank/SuggestiveQuestions";
import QB_Questions from "./Pages/questionbank/QB_Questions";
import UpdateQuestion from "./Pages/question/UpdateQuestion";
import CopyQuestion from "./Pages/question/CopyQuestion";
import UpdateQuiz from "./Pages/quiz/UpdateQuiz";
import AppLayout from "./components/MainLayout/AppLayout";
import ForgotPasswordOTP from "./Pages/forgot-password/ForgotPasswordOTP";
import RecoverPassword from "./Pages/forgot-password/RecoverPassword";
import StudentRegisteration from "./Pages/not-found/StudentRegisteration";
import ChangePassword from "./Pages/profile/ChangePassword";
import ProfessorVerified from "./Pages/professor/professor";
const protectedRoutes = [
  {
    path: "app",
    element: <AppLayout />,
    children: [
      // { path: "dashboard", element: <Dashboard /> },
      { path: "professor/dashboard", element: <Dashboard /> },
      {
        path: "professor/profile/verification",
        element: <ProfessorAccountVerification />,
      },
      {
        path: "professor/profile/update",
        element: <ProfessorUpdateProfile />,
      },
      {
        path: "professor/question/create:qbId",
        element: <CreateQuestion />,
      },
      {
        path: "professor/questionbank/manage",
        element: <QuestionBank />,
      },
      {
        path: "professor/questionbank/create",
        element: <CreateQuestionBank />,
      },
      {
        path: "professor/questionbank/open/:qbId",
        element: <QB_Questions />,
      },
      {
        path: "professor/questionbank/suggested_questions/:qbId",
        element: <SuggestiveQuestion />,
      },
      {
        path: "professor/questionbank/create_question/:qbId",
        element: <CreateQuestion />,
      },
      {
        path: "professor/questionbank/question/update/:questionId",
        element: <UpdateQuestion />,
      },
      {
        path: "professor/questionbank/question/sugestive/copy/:questionBankId/:questionId",
        element: <CopyQuestion />,
      },
      {
        path: "professor/quiz/create",
        element: <CreateQuiz />,
      },
      {
        path: "professor/quiz/manage",
        element: <Quiz />,
      },
      {
        path: "professor/quiz/edit/:quizId",
        element: <UpdateQuiz />,
      },
      {
        path: "professor/quiz/chooseQuestions/manually/:quizId",
        element: <ChooseManually />,
      },
      {
        path: "professor/quiz/chooseQuestions/automatic/:quizId",
        element: <ChooseAutomatic />,
      },
      {
        path: "professor/quiz/result/:quizId",
        element: <Result />,
      },
      {
        path: "professor/quiz/edit/:quizId",
        element: <Result />,
      },
      {
        path: "professor/quiz/open/:quizId",
        element: <OpenQuiz />,
      },
      {
        path: "professor/quiz/enrollment/:quizId",
        element: <Enrollments />,
      },
      {
        path:"professor/changePassword",
        element: <ChangePassword/>
      },
      {
        path:"professor/verified",
        element: <ProfessorVerified/>
      },


      { path: "professor/profile/view/:studentId", 
      element: <OpenProfile /> },
      // { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];
const routes = [
  { path: "/signin", element: <Signin /> },
  { path: "/studentRegisteration", element: <StudentRegisteration /> },
  { path: "/signup", element: <Signup /> },
  { path: "/forgotpassword", element: <ForgotPassword /> },
  { path: "/forgotpassword/otp/:email", element: <ForgotPasswordOTP /> },
  { path: "/forgotpassword/recover_password", element: <RecoverPassword /> },
  { path: "/otp_verification/:email", element: <OTP /> },
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/app/professor/dashboard" /> },
      // { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];
export { routes, protectedRoutes };
