import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosClient from "./../../axios-client";
// import { Validators } from "../../utils/Validator";
import { useFormik } from "formik";
import { updateQuestionValidation } from "../../schemas";
import { validateInput } from "../../utils/Validator";
import { toast } from "react-toastify";
import "./createquestion.css";
import Constants from "../../Constants/Constants";
import { useStateContext } from "../../contexts/ContextProvider";
import Select from "react-select";
const UpdateQuestion = () => {
  const { setProgress } = useStateContext();
  const APP_URL = Constants.APP_URL;
  // const submitBtn = useRef();
  const navigate = useNavigate();
  const params = useParams();

  const initialValues = {
    questionText: "",
    question_image: "",
    correctAnswer: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option1_image: "",
    option2_image: "",
    option3_image: "",
    option4_image: "",
  };

  const {
    values,
    errors,
    setValues,
    handleBlur,
    touched,
    isSubmitting,
    setSubmitting,
    isValid,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: updateQuestionValidation,
    onSubmit: async (values, action) => {
      setProgress(10);
        let formData = new FormData();
        formData.append("question_text", values.questionText);
        // formData.append("category",values.category);
        formData.append("correct_answer", values.correctAnswer);
        formData.append("question_image", values.question_image);
        formData.append("answer1_text", values.option1);
        formData.append("answer2_text", values.option2);
        formData.append("answer3_text", values.option3);
        formData.append("answer4_text", values.option4);
        formData.append("answer1_image", values.option1_image);
        formData.append("answer2_image", values.option2_image);
        formData.append("answer3_image", values.option3_image);
        formData.append("answer4_image", values.option4_image);
        formData.append("question_bank_id", values.question_bank_id);
        // sending the data using axiosClient
        // formData.forEach((value, key) => {
        //   console.log(`${key}: ${value}`);
        // });
        try {
          const csrf = await axiosClient.get("sanctum/csrf-cookie").then((res) => {
            setProgress(20);
            axiosClient
              .post(
                "api/questionbank/question/update/" + params.questionId,
                formData
              )
              .then((res) => {
                setProgress(30);
                if (res.data.success == true) {
                  setProgress(100);
                  toast("Updated Successfully.");
                  // return navigate(-1);
                  action.resetForm();
                  setSubmitting(false);

                  return navigate(
                    "../professor/questionbank/open/" +
                      values.question_bank_id,
                    { replace: true }
                  );
                }
              })
              .catch((err) => {
                toast("Login faild please try again...", {});
                setProgress(100);
                setSubmitting(false);
              });
          });
        } catch (error) {
          toast(error.message, {});
          setProgress(100);
          setSubmitting(false);
        }


    },
  });


    async function getQuestion(url = "") {
    try {
      setProgress(10);
      const res = await axiosClient.get(url);
      if (res.data.success == true) {
        setProgress(90);
        setValues({
          questionText: res.data.data.questionData[0].question_text,
          question_image: res.data.data.questionData[0].question_image_url,
          correctAnswer: res.data.data.questionData[0].correct_answer,
          option1: res.data.data.questionData[0].answer1_text,
          option2: res.data.data.questionData[0].answer2_text,
          option3: res.data.data.questionData[0].answer3_text,
          option4: res.data.data.questionData[0].answer4_text,
          option1_image: res.data.data.questionData[0].answer1_image_url,
          option2_image: res.data.data.questionData[0].answer2_image_url,
          option3_image: res.data.data.questionData[0].answer3_image_url,
          option4_image: res.data.data.questionData[0].answer4_image_url,
          question_bank_id: res.data.data.questionData[0].question_bank_id,
        });
        setProgress(100);
      }
    } catch (error) {
      toast(error.message, {});
      setProgress(100);
    }
  }
  useEffect(() => {
    getQuestion("api/questionbank/question/edit/" + params.questionId);
  }, []);

  /**
   *
   * @param {*} value
   */

   const answers = [
    { value: "1", label: "answer 1" },
    { value: "2", label: "answer 2" },
    { value: "3", label: "answer 3" },
    { value: "4", label: "answer 4" },
  ];
  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <p className="welcome">Update Question</p>
      <div className="create-question-container">
        <div className="create-question-left">
          <div className="question">
            <div className="form-label">
              <label htmlFor="">Question</label>
              {errors.questionText && touched.questionText ? (
                <span className="error">{errors.questionText}</span>
              ) : null}
            </div>
            <textarea
              dir="auto"
              name="questionText"
              value={values.questionText}
              onChange={handleChange}
              onBlur={handleBlur}
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div className="row">
            <div className="right-form-group">
              <div className="form-group">
                <div className="form-label">
                  <label htmlFor="name">Correct Answer</label>
                  {errors.correctAnswer && touched.correctAnswer ? (
                    <span className="error">{errors.correctAnswer}</span>
                  ) : null}
                </div>
          
                <Select
                  // className="select_formik_input"
                  name="correctAnswer"
                  onBlur={handleBlur}
                  value={answers.find(
                    (option) => option.value == values.correctAnswer
                  )}
                  options={answers}
                  onChange={(selectedOption) => {
                    setValues((prevState) => ({
                      ...prevState,
                      correctAnswer: selectedOption.value,
                    }));
                  }}
                  placeholder="please select the correct answer"
                />
              </div>
            </div>
            <div className="upload-profile">
              <label>Question Bank Image</label>
              <label htmlFor="question_image" className="upload-img-wrap">
                {values.question_image && (
                  <img
                    src={
                      values.question_image != null
                        ? typeof values.question_image != "string"
                          ? URL.createObjectURL(values.question_image)
                          : `${APP_URL}/storage/thumbnail_images/questions/question/` +
                            values.question_image
                        : ""
                    }
                    alt=""
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="question_image"
                id="question_image"
                onChange={(e) =>
                  setValues((prevState) => ({
                    ...prevState,
                    question_image: e.target.files[0],
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="create-question-right">
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Option1</label>
              {errors.option1 && touched.option1 ? (
                <span className="error">{errors.option1}</span>
              ) : null}
            </div>
            <div className="input-file-wrap">
              <input
                dir="auto"
                type="text"
                name="option1"
                value={values.option1}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="option 1"
              />
              <label htmlFor="option1Img" className="select-file-wrap">
                {values.question_image && (
                  <img
                    src={
                      values.option1_image != null
                        ? typeof values.option1_image != "string"
                          ? URL.createObjectURL(values.option1_image)
                          : `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                            values.option1_image
                        : ""
                    }
                    alt=""
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="option1_image"
                id="option1Img"
                onChange={(e) =>
                  setValues((prevState) => ({
                    ...prevState,
                    option1_image: e.target.files[0],
                  }))
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Option2</label>
              {errors.option2 && touched.option2 ? (
                <span className="error">{errors.option2}</span>
              ) : null}
            </div>
            <div className="input-file-wrap">
              <input
                dir="auto"
                type="text"
                name="option2"
                value={values.option2}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="option 2"
              />
              <label htmlFor="option2Img" className="select-file-wrap">
                {values.question_image && (
                  <img
                    src={
                      values.option2_image != null
                        ? typeof values.option2_image != "string"
                          ? URL.createObjectURL(values.option2_image)
                          : `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                            values.option2_image
                        : ""
                    }
                    alt=""
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="option2_image"
                id="option2Img"
                onChange={(e) =>
                  setValues((prevState) => ({
                    ...prevState,
                    option2_image: e.target.files[0],
                  }))
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Option3</label>
              {errors.option3 && touched.option3 ? (
                <span className="error">{errors.option3}</span>
              ) : null}
            </div>
            <div className="input-file-wrap">
              <input
                dir="auto"
                type="text"
                name="option3"
                // className={errors.option3.message && "error"}
                value={values.option3}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="option 3"
              />
              <label htmlFor="option3Img" className="select-file-wrap">
                {values.question_image && (
                  <img
                    src={
                      values.option3_image != null
                        ? typeof values.option3_image != "string"
                          ? URL.createObjectURL(values.option3_image)
                          : `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                            values.option3_image
                        : ""
                    }
                    alt=""
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="option3_image"
                id="option3Img"
                onChange={(e) =>
                  setValues((prevState) => ({
                    ...prevState,
                    option3_image: e.target.files[0],
                  }))
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Option4</label>
              {errors.option4 && touched.option4 ? (
                <span className="error">{errors.option4}</span>
              ) : null}
            </div>
            <div className="input-file-wrap">
              <input
                dir="auto"
                type="text"
                name="option4"
                // className={errors.option4.message && "error"}
                value={values.option4}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="option 4"
              />
              <label htmlFor="option4Img" className="select-file-wrap">
                {values.question_image && (
                  <img
                    src={
                      values.option4_image != null
                        ? typeof values.option4_image != "string"
                          ? URL.createObjectURL(values.option4_image)
                          : `${APP_URL}/storage/thumbnail_images/questions/answers/` +
                            values.option4_image
                        : ""
                    }
                    alt=""
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="option4_image"
                id="option4Img"
                onChange={(e) =>
                  setValues((prevState) => ({
                    ...prevState,
                    option4_image: e.target.files[0],
                  }))
                }
              />
            </div>
          </div>

          <div className="form-group">
            <button
              type="submit"
              disabled={!isValid}
            >
              {isSubmitting ? "updating..." : "update"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateQuestion;
