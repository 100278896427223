import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosClient from "./../../axios-client";
import "./createquiz.css";
import { useStateContext } from "../../contexts/ContextProvider";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import "./createquiz.css";

const CreateQuiz = () => {
  const { setProgress } = useStateContext();
  const navigate = useNavigate();
  const params = useParams();
  const [qbLists, setQBLists] = useState([]);
  const [quizQBLists, setQuizQBList] = useState([]);
  const [totalQuestions, setTotQuestions] = useState(0);
  const [remainingQuestion, setRemainingQuestions] = useState();
  const [addedQuestions, setAddedQuestion] = useState(0);
  const [totalAddedQuestion, setTotalAddedQuestion] = useState({
    question_id: null,
    quantity: 0,
  });
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [checkQuestionFlag, setCheckQuestionFlag] = useState(false);
  const [quizQuestionsState, setQuizQuestionsState] = useState([]);
  
  


  const createQuizValidation = Yup.object({
    name: Yup.string().min(3).required("Please enter the quiz name"),
    timeLimit: Yup.object().required("Please select time limit"),
    score: Yup.number().required("Please enter score amount"),
    noOfQuestions: Yup.number()
      .min(1)
      .max(totalQuestions)
      .typeError("Please enter a valid number")
      .required("Please enter number of question")
      .positive("Please enter a positive number")
      .integer("Please enter a whole number")
      .test(
        "is-number",
        "Please enter a valid number",
        (value) => !isNaN(value)
      ),
    price: Yup.object().required("please select the price"),
    date: Yup.date()
      .min(new Date(), "date must be after now")
      .required("please specify the date"),
    // quizDescription: Yup.string(),
    timeDuration: Yup.number()
      .min(1)
      .typeError("Please enter a valid number")
      .required("Please enter number of question")
      .positive("Please enter a positive number")
      .integer("Please enter a whole number")
      .test(
        "is-number",
        "Please enter a valid number",
        (value) => !isNaN(value)
      ),

  });

  const initialValues = {
    name: "",
    timeLimit: "",
    score: "",
    noOfQuestions: 0,
    price: "",
    date: "",
    timeDuration: "",
    quizDescription: "",
    privacy: "",
    password: "",
    quizImage: "",
  };
  const {
    values,
    setValues,
    errors,
    handleBlur,
    setFieldValue,
    touched,
    isSubmitting,
    setSubmitting,
    isValid,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: createQuizValidation,
    onSubmit: async (values, action) => {
      let time_limit = values.timeLimit
      let price_limit = values.price
      
      setProgress(10);
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("timeLimit", time_limit.value);
      formData.append("score", values.score);
      formData.append("noOfQuestions", values.noOfQuestions);
      formData.append("timeDuration", values.timeDuration);
      formData.append("price", price_limit.value);
      formData.append("date", values.date);
      formData.append("quizDescription", values.quizDescription);
      formData.append("privacy", values.privacy);
      formData.append("password", values.password);
      let quizQB = quizQBLists.filter((qq) => qq.totQty >= 1);
      quizQB = quizQB.map((qb, index) => ({
        id: qb.id,
        name: qb.name,
        totQty: qb.totQty,
      }));
      formData.append("questionBanks", JSON.stringify([...quizQB]));
      formData.append("quizImg", values.quizImage);
      const url =
        values.submitType === "manually"
          ? "api/quiz/create/manually"
          : "api/quiz/create/automatic";
      setProgress(20);
      try {
          const csrf = await axiosClient.get("sanctum/csrf-cookie").then((res) => {
            axiosClient
              .post(url, formData)
              .then((res) => {
                if (res.data.success == true) {
                  setProgress(100);
                  toast("Quiz created successfully.",{})
                  //set questions and send to next page
                  if (values.submitType == "manually") {
                    setSubmitting(false)
                    return navigate(
                      "../professor/quiz/chooseQuestions/manually/" +
                        res.data.data.quizId
                    );
                  } else {
                    setQuizQuestionsState(res.data.data.quizQuestions);
                    setSubmitting(false)
                    return navigate(
                      "../professor/quiz/chooseQuestions/automatic/" +
                        res.data.data.quizId
                    );
                  }
                }
              })
              .catch((err) => {
                toast("Quiz creation faild please try again...",{})
                  setProgress(100)
                  setSubmitting(false)
              });
          });
        } catch (error) {
          setProgress(100)
          setSubmitting(false)
          toast(error.message)
        }

    },
  });

 

  /**
   *
   * @param {*} value
   */
 const handleSetQuestionBanks = (selectedOption) => {
    if (remainingQuestion > 0) {
      let selected = selectedOption.value;

      const newQBLists = qbLists.filter((qb) => qb.id != selected);
      const newQuizQBLists = qbLists.filter((qb) => qb.id == selected);

      //add default total question into it.
      
      newQuizQBLists[0].totQty = values.noOfQuestions - addedQuestions;
      setQBLists(newQBLists);
      setQuizQBList((qql) => [...qql, ...newQuizQBLists]);
      setAddedQuestion((prev) => prev + remainingQuestion);
      const addQ = addedQuestions;
      setRemainingQuestions(addQ);
    }
  };

  useEffect(() => {}, [
    addedQuestions,
    remainingQuestion,
    values.noOfQuestions,
  ]);
  async function getQuestionBanks(url = "") {
    try {
      const res = await axiosClient.get(url);
      if (res.data.success == true) {
        setQBLists(res.data.data.questionBanks);
        setTotQuestions(
          res.data.data.questionBanks.reduce(
            (prevValue, qb) => prevValue + Number(qb.total_question),
            0
          )
        );
      }
    } catch (error) {}
  }
  useEffect(() => {
    getQuestionBanks("api/questionbank/manage");
  }, []);

  //this function add no of question to be used in a quiz
  const handleAddTotQue = (e, queBankId) => {
    if (e.target.value != "") {
      // ++==++
      const newQuantity = e.target.value;
      setTotalAddedQuestion((prevState) => {
        if (prevState[queBankId]) {
          return {
            ...prevState,
            [queBankId]: { ...prevState[queBankId], quantity: newQuantity },
          };
        } else {
          return {
            ...prevState,
            [queBankId]: { question_id: queBankId, quantity: newQuantity },
          };
        }
      });

      // ++==+++
      //now subtract this value from remainingQuestion state
      const index = quizQBLists.findIndex((object) => {
        return object.id == queBankId;
      }); // 1
      let tempRemainingQueVal = 0;
      if (quizQBLists[index].totQty > parseInt(e.target.value)) {
        tempRemainingQueVal =
          remainingQuestion +
          (quizQBLists[index].totQty - parseInt(e.target.value));
      } else {
        tempRemainingQueVal =
          remainingQuestion -
          (parseInt(e.target.value) - quizQBLists[index].totQty);
      }
      setRemainingQuestions(tempRemainingQueVal);
      setAddedQuestion(values.noOfQuestions - tempRemainingQueVal);
      setQuizQBList((prevQQL) => {
        return prevQQL.map((qblist) => ({
          ...qblist,
          ["totQty"]:
            qblist.id == parseInt(queBankId)
              ? parseInt(e.target.value)
              : qblist.totQty,
        }));
      });
    }
  };
  const handleRemoveQB = (e, id) => {
    const { question_id, ...newState } = totalAddedQuestion;
    delete newState[id];
    setTotalAddedQuestion(newState);

    let newQBLists = quizQBLists.filter((qb) => qb.id == id);
    let newQuizQBLists = quizQBLists.filter((qb) => qb.id != id);
    setRemainingQuestions((prev) => prev + newQBLists[0].totQty);
    setAddedQuestion((prev) => prev - newQBLists[0].totQty);
    setQBLists((qql) => [...qql, ...newQBLists]);
    setQuizQBList([...newQuizQBLists]);
  };

  const time_limit = [
    { value: "open", label: "open" },
    { value: "limit", label: "limit" },
  
  ];
  const price_limit = [
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
  ];



  useEffect(() => {
    if (
      Object.values(totalAddedQuestion) &&
      Object.values(totalAddedQuestion) != null
    ) {
      const newTotalQuantity = Object.values(totalAddedQuestion).reduce(
        (total, question) => {
          if (question) {
            return Number(total) + Number(question.quantity);
          }
          return total;
        },
        0
      );
      setTotalQuantity(newTotalQuantity);
    }
  }, [totalAddedQuestion]);

  useEffect(() => {
    const filteredQBLists = quizQBLists.filter(
      (qb) => qb.totQty > parseInt(qb.total_question)
    );
    if (filteredQBLists.length > 0) {
      setCheckQuestionFlag(true);
    } else {
      setCheckQuestionFlag(false);
    }
  }, [quizQBLists]);
  return (
    <form action="" onSubmit={handleSubmit}>
      <p className="create-quiz">Create Quiz</p>
      <div className="cq-container">
        <div className="cq-left">
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Name</label>
              {errors.name && touched.name ? (
                <span className="error">{errors.name}</span>
              ) : null}
            </div>
            <input
              dir="auto"
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Quiz Name"
            />
          </div>
          <div className="form-group-password">
            <div className="form-group ">
              <div className="form-label">
                <label htmlFor="name">Number of Questions</label>
              </div>
              <input
                dir="auto"
                type="number"
                name="noOfQuestions"
                min="1"
                value={values.noOfQuestions}
                onBlur={handleBlur}
                onChange={(e) => {
                  setValues((prevValue) => ({
                    ...prevValue,
                    noOfQuestions: parseInt(e.target.value),
                  }));
                  setRemainingQuestions(parseInt(e.target.value));
                }}
                placeholder="No of questions"
              />

              <div className="form-error">
                {errors.noOfQuestions && touched.noOfQuestions ? (
                  <span className="error">{errors.noOfQuestions}</span>
                ) : null}
              </div>
            </div>

            <div className="form-group">
              <div className="form-label">
                <label htmlFor="name">Score</label>
              </div>
              <input
                dir="auto"
                type="number"
                min="1"
                name="score"
                value={values.score}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="score"
              />
              <div className="form-error">
                {errors.score && touched.score ? (
                  <span className="error">{errors.score}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="form-group-password">
            <div className="form-group ">
              <div className="form-label">
                <label htmlFor="name">Time Limit</label>
              </div>
          
              <Select
                name="timeLimit"
                onBlur={handleBlur}
                value={values.timeLimit}
                options={time_limit}
                onChange={(selectedOption) => {
                  setValues((prevValue) => ({
                    ...prevValue,
                    timeLimit: selectedOption,
                  }));
                }}
                placeholder="Please specify"
              />

              <div className="form-error">
                {errors.timeLimit && touched.timeLimit ? (
                  <span className="error">{errors.timeLimit}</span>
                ) : null}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label">
                <label htmlFor="name">Price</label>
                {errors.price && touched.price ? (
                <span className="error">{errors.price}</span>
              ) : null}
              </div>
              <Select
                name="price"
                onBlur={handleBlur}
                value={values.price}
                options={price_limit}
                onChange={(selectedOption) => {
                  setValues((prevValue) => ({
                    ...prevValue,
                    price: selectedOption,
                  }));
                }}
                placeholder="Please specify"
              />
              <div className="form-error"></div>
            </div>
          </div>
      

          <div className="form-group-password">
            <div className="form-group">
              <div className="form-label">
                <label htmlFor="name">Date</label>
                {errors.date && touched.date ? (
                  <span className="error">{errors.date}</span>
                ) : null}
              </div>
              <input
                type="datetime-local"
                name="date"
                value={values.date}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter quiz date "
              />
            </div>
            <div className="form-group">
              <div className="form-label">
                <label htmlFor="name">Time Duration in minute</label>
                {errors.timeDuration && touched.timeDuration ? (
                  <span className="error">{errors.timeDuration}</span>
                ) : null}
              </div>
              <input
                type="text"
                name="timeDuration"
                value={values.timeDuration}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="time duration"
              />
            </div>
          </div>
          <div className="score">
            <div className="form-label">
              <label htmlFor="">Quiz Description</label>
              {errors.quizDescription && touched.quizDescription ? (
                <span className="error">{errors.quizDescription}</span>
              ) : null}
            </div>
            <textarea
              dir="auto"
              onBlur={handleBlur}
              onChange={handleChange}
              name="quizDescription"
              value={values.quizDescription}
              cols="30"
              rows="9"
            />
          </div>
        </div>
        <div className="cq-right">
          <div className="row">
            <div className="left-form-group">
            
              <div className="form-group">
                <div className="form-label">
                  <label htmlFor="name">Password</label>
                 
                </div>
                <input
                  dir="auto"
                  type="text"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="password"
                  label={"password"}
                />
              </div>
            </div>
            <div className="upload-profile">
              <label>Quiz Image</label>
              <label htmlFor="quizImage" className="upload-img-wrap">
                {values.quizImage && (
                  <img
                    src={
                      values.quizImage != ""
                        ? URL.createObjectURL(values.quizImage)
                        : ""
                    }
                    alt=""
                  />
                )}
                <span>
                  <i className="fa fa-camera"></i>
                </span>
              </label>
              <input
                type="file"
                name="quizImage"
                id="quizImage"
                onChange={(e) =>
                  setValues((prevState) => ({
                    ...prevState,
                    quizImage: e.target.files[0],
                  }))
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="name">Question Bank</label>
              {errors.questionBank && touched.questionBank ? (
                <span className="error">{errors.questionBank}</span>
              ) : null}
            </div>
 

            <Select
              name="questionBank"
              onBlur={handleBlur}
              value={values.questionBank}
              isDisabled={
                !values.noOfQuestions ||
                isNaN(values.noOfQuestions) ||
                values.noOfQuestions <= 0
              }
              options={
                qbLists &&
                qbLists.map((qb) => ({
                  value: qb.id,
                  label: (
                    <div>
                      <span>Total-- ({qb.total_question}) :</span>
                      {qb.name}
                    </div>
                  ),
                }))
              }
              onChange={(selectedOption) => {
                setValues((prevValue) => ({
                  ...prevValue,
                  questionBank: selectedOption.value,
                }));
                handleSetQuestionBanks(selectedOption);
              }}
              placeholder="Please select question banks"
            />
         

            <div className="added-question-banks">
              <div className="form-label">
                <label htmlFor="">
                  Added Question Banks{" "}
                  <strong
                    style={{
                      color: totalQuantity > values.noOfQuestions ? "red" : "",
                    }}
                  >
                    {" "}
                    ({totalQuantity + "/" + values.noOfQuestions})
                    
                  </strong>
                </label>
                {totalQuantity > values.noOfQuestions ? (
                  <span className="error">
                    you added questions more then number of equestion
                  </span>
                ) : null}
              </div>
              <div className="question-banks">
                {quizQBLists.length < 1 &&
                  "Your selected question banks will be appeared here"}
                {quizQBLists.length > 0 &&
                  quizQBLists.map((qb) => (
                    <div key={qb.id}>
                      <div className="question-bank">
                        <span>{qb.name}</span>
                        <div className="qb-info">
                          <input
                            type="text"
                            name="totQuestion"
                            style={{
                              border: "1px solid",
                              borderColor:
                                qb.totQty > parseInt(qb.total_question)
                                  ? "  red"
                                  : " green",
                              transition: "border-width 0.5s ease-in-out",
                              animation:
                                qb.totQty > parseInt(qb.total_question)
                                  ? "pulse 1s ease-in-out infinite"
                                  : "none",
                            }}
                            defaultValue={qb.totQty}
                            onChange={(e) => handleAddTotQue(e, qb.id)}
                            autoFocus
                            onInput={(e) => handleAddTotQue(e, qb.id)}
                            onFocus={(e)=> handleAddTotQue(e,qb.id)}
                            className="tot-question"
                          />

                          <span>Question</span>
                          <i
                            className="fa fa-close"
                            onClick={(e) => handleRemoveQB(e, qb.id)}
                          ></i>
                        </div>
                        <div></div>
                      </div>
                      <small
                        style={{
                          textAlign: "left",
                          color: "#6c757d",
                          width: "100%",
                          marginLeft: "0%",
                        }}
                      >
                        {qb.totQty > parseInt(qb.total_question)
                          ? `this question bank has ${qb.total_question} questions`
                          : ""}
                      </small>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="add-question-btns">
            <button
              disabled={
                !isValid ||
                totalQuantity != values.noOfQuestions ||
                checkQuestionFlag
              }
              type="submit"
              onClick={() => setFieldValue("submitType", "manually")}
            >
           {isSubmitting ? "please wait..." : "Add Question Manually"}   
            </button>
            <button
              disabled={
                !isValid ||
                totalQuantity != values.noOfQuestions ||
                checkQuestionFlag
              }
              type="submit"
              onClick={() => setFieldValue("submitType", "automatic")}
            >
             {isSubmitting ? "please wait..." : "Add Question Automatic" }  
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateQuiz;
